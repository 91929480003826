export const columnsDataCheck = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "NOM",
    accessor: "nom",
  },
  {
    Header: "ANNONCEUR",
    accessor: "annonceur",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },


];
export const columnsSubcategories = [
  {
    Header: "NOM",
    accessor: "nom",
  },
  {
    Header: "CATEGORIE",
    accessor: "categorie",
  },
  {
    Header: "ACTION",
    accessor: "action",
  },
];
export const columnsSecteurs = [
  {
    Header: "NOM",
    accessor: "nom",
  },
  {
    Header: "VILLE",
    accessor: "ville",
  },
  {
    Header: "ACTION",
    accessor: "action",
  },
];
export const columnsDataUsers = [

  {
    Header: "NOM COMPLET",
    accessor: "nom",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "TELEPHONE",
    accessor: "phone",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE DE CREATION",
    accessor: "date",
  },
  {
    Header: "TYPE",
    accessor: "ProPart",
  },
  {
    Header: "ADMIN",
    accessor: "isAdmin",
  },
  {
    Header: "ACTION",
    accessor: "action",
  },


];
export const columnsDataComplex = [
  {
    Header: "NOM",
    accessor: "name",
  },
  {
    Header: "CATEGORIE",
    accessor: "category",
  },
  {
    Header: "SUBCATEGORIE",
    accessor: "subcategory",
  },
  {
    Header: "TYPE",
    accessor: "type",
  },

  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "ACTION",
    accessor: "action",
  },
];
export const columnsDataComplexSc = [
  {
    Header: "NOM",
    accessor: "name",
  },
  {
    Header: "CHAMPS",
    accessor: "customFieldValue",
  },
  {
    Header: "CATEGORIE",
    accessor: "category",
  },
  {
    Header: "SUBCATEGORIE",
    accessor: "subcategory",
  },
  {
    Header: "TYPE",
    accessor: "type",
  },

  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "ACTION",
    accessor: "action",
  },
];
export const columnsDataSubs = [

  {
    Header: "NOM",
    accessor: "name",
  },
  {
    Header: "CATEGORIE",
    accessor: "category",
  },
  {
    Header: "PRIX(MAD)",
    accessor: "price",
  },
  {
    Header: "RENOUVELEMENT(jours)",
    accessor: "reposts",
  },
  {
    Header: "PROMO(jours)",
    accessor: "days",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "ACTION",
    accessor: "action",
  },


];

