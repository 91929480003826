// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  Text,
  useColorModeValue,
  Button,
  Stack,
  Center,
  IconButton,
} from '@chakra-ui/react';
import axios from 'axios';
import Card from 'components/card/Card.js';
import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { FaStoreAlt } from 'react-icons/fa';
import { FaUserTie } from 'react-icons/fa';
import {
  MdPersonAdd,
  MdPhone,
  MdPersonAddDisabled,
  MdPersonSearch,
  MdSettings,
  MdLogout,
} from 'react-icons/md';
import { t } from 'helpers/TransWrapper';

export default function Banner(props) {
  const { banner, avatar, name, job, posts, nbrAds, propart } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue(
    'white !important',
    '#111C44 !important'
  );
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  const [followersCount, setFollowersCount] = useState();
  const [followingCount, setFollowingCount] = useState();
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);

  const history = useHistory();
  const logout = e => {
    localStorage.removeItem('user-token');
    localStorage.removeItem('user-img');
    localStorage.removeItem('token');

    history.push('/auth/sign-in');
    history.go(0);
  };
  const updateProfile = e => {
    history.push('/register/updateUser');
    history.go(0);
  };

  const fetchFollowers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/users/${currentUser._id}/followers`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      const followers = response.data;
      setFollowersCount(followers.length);

      setFollowers(followers);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFollowers();
  }, [currentUser._id, followers]);
  const fetchFollowing = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/users/${currentUser._id}/following`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      const following = response.data;
      setFollowing(following);
      setFollowingCount(following.length);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFollowing();
  }, [currentUser._id, following]);

  return (
    <Card shadow="lg" p={0} mb={{ base: '0px', lg: '20px' }} align="center">
      <Box
        borderBottomRadius="0px"
        borderBottomEndRadius="0px"
        bg={`url(${banner})`}
        bgSize="cover"
        borderRadius="15px"
        h="160px"
        w="100%"
      />
      {propart === 'pro' ? (
        <Flex
          position="absolute"
          top="-6px"
          right="-6px"
          align="center"
          justify="center"
          bg="green.500"
          borderRadius="full"
          w="35px"
          h="35px"
          boxShadow="0 0 4px rgba(0, 0, 0, 0.4)"
        >
          <IconButton
            icon={<FaStoreAlt />}
            aria-label="Shop Account"
            variant="solid"
            colorScheme="white"
            size="xl"
          />
        </Flex>
      ) : (
        <Flex
          position="absolute"
          top="-6px"
          right="-6px"
          align="center"
          justify="center"
          bg="red.500"
          borderRadius="full"
          w="35px"
          h="35px"
          boxShadow="0 0 4px rgba(0, 0, 0, 0.4)"
        >
          <IconButton
            icon={<FaUserTie />}
            aria-label="Shop Account"
            variant="solid"
            colorScheme="white"
            size="xl"
          />
        </Flex>
      )}
      <Avatar
        mx="auto"
        src={avatar}
        h="90px"
        w="90px"
        mt="-43px"
        //border={`2px solid ${props.propart === 'pro' ? 'green' : borderColor}`}
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {' '}
        {name}{' '}
      </Text>{' '}
      <Text color={textColorSecondary} fontSize="sm">
        {' '}
        {job}{' '}
      </Text>{' '}
      <Center>
        <Stack align="center" direction={['column', 'row']} spacing="60px">
          <Box>
            <Flex mx="auto" direction="column">
              <Flex mx="auto">
                <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                  {posts ? posts : 0}
                </Text>
                <Text ml={1} mt={2}>
                  /{nbrAds ? nbrAds : 0}
                </Text>
              </Flex>
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                {t('Annonces')}{' '}
              </Text>{' '}
            </Flex>{' '}
          </Box>{' '}
          <Box>
            <Flex mx="auto" direction="column">
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {' '}
                {followersCount ? followersCount : 0}{' '}
              </Text>{' '}
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                {t('Abonnés')}{' '}
              </Text>{' '}
            </Flex>{' '}
          </Box>{' '}
          <Box>
            <Flex mx="auto" direction="column">
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {' '}
                {followingCount ? followingCount : 0}{' '}
              </Text>{' '}
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                {t('Abonnements')}{' '}
              </Text>{' '}
            </Flex>{' '}
          </Box>{' '}
        </Stack>{' '}
      </Center>{' '}
      <Stack mt={3} p={4}>
        <Button
          onClick={updateProfile}
          leftIcon={<MdSettings />}
          variant="darkBrand"
          fontWeight="regular"
          fontSize="md"
          minW="100%"
          mx="auto"
        >
          {t('Modifier mon profile')}{' '}
        </Button>{' '}
        <Button
          leftIcon={<MdLogout />}
          variant="solid"
          colorScheme="red"
          fontWeight="regular"
          fontSize="md"
          minW="100%"
          mx="auto"
          onClick={logout}
        >
          {t('Se déconnecter')}{' '}
        </Button>{' '}
      </Stack>{' '}
    </Card>
  );
}
