// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA_9I3q9_6iCIM99dOtBWFiCZovhxKMH6g",
  authDomain: "jumatikstorage.firebaseapp.com",
  projectId: "jumatikstorage",
  storageBucket: "jumatikstorage.appspot.com",
  messagingSenderId: "133815620088",
  appId: "1:133815620088:web:a8eae205c42717161cc60d",
  measurementId: "G-XZX1VWSQMQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
