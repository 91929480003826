import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import axios from 'axios';

import useAlert from '../../../components/alert/useAlert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 } from 'uuid';
import { HiOutlinePlusCircle, HiOutlineMinusCircle } from 'react-icons/hi';
import { InfoIcon } from '@chakra-ui/icons';
// Chakra imports
import {
  ThemeProvider,
  theme,
  Box,
  Divider,
  VStack,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Tooltip,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Center,
  Icon,
  Input,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  useColorModeValue,
  Stack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Avatar,
  AvatarBadge,
  IconButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Select,
  Image,
  HStack,
  useDisclosure,
  CloseButton,
  Grid,
  GridItem,
  Progress,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Wrap,
  WrapItem,
  useColorMode,
  AspectRatio,
  Link,
} from '@chakra-ui/react';
import { CloseIcon, AddIcon, MinusIcon } from '@chakra-ui/icons';
import { AiOutlineVideoCameraAdd, AiOutlineFileAdd } from 'react-icons/ai';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../firebase';
import Lottie from 'react-lottie';
import verifAnim from '../../../assets/verifAnim.json'; // import your Lottie animation JSON file

import illustration from 'assets/img/auth/auth.png';
import Dropzone from 'views/admin/profile/components/Dropzone';
import Card from 'components/card/Card.js';
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { t } from 'helpers/TransWrapper';
import { FiChevronDown } from 'react-icons/fi';
import CountryList from 'country-list-with-dial-code-and-flag';
import { io } from 'socket.io-client';
import { useSocket } from '../../../contexts/SocketContext';
import socket from '../../../socket';
import {
  MdCameraEnhance,
  MdCategory,
  MdPerson,
  MdSearch,
  MdHomeFilled,
  MdDelete,
  MdImage,
  MdCheck,
  MdOutlineClear,
} from 'react-icons/md';
import Memberships from 'views/admin/memberships';
import { MdWorkspacePremium, MdStars } from 'react-icons/md';
import { Document, Page, pdfjs } from 'react-pdf';
const FilePreview = ({ file, onDelete }) => {
  const fileURL = URL.createObjectURL(file);
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
  const GetFilePreview = () => {
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess() {
      setNumPages(numPages);
    }

    switch (file.type) {
      case 'application/pdf':
        return (
          <Box
            height="300px"
            width="300px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            borderRadius={'20px'}
          >
            <IconButton
              position="absolute"
              top="2"
              right="2"
              zIndex="10"
              colorScheme="red"
              aria-label="Delete File"
              icon={<CloseIcon />}
              size="sm"
              onClick={onDelete}
            />
            <Box
              borderRadius={'20px'}
              height="100%"
              width="100%"
              overflow="auto"
            >
              <Document
                file={fileURL}
                onLoadSuccess={onDocumentLoadSuccess}
                options={{
                  cMapUrl: 'cmaps/',
                  cMapPacked: true,
                  nativeImageDecoderSupport: 'none',
                }}
              >
                <Page
                  pageNumber={pageNumber}
                  width={300} // Set the width of the page
                  height={300} // Set the height of the page
                />
              </Document>
              <Text>
                Page {pageNumber} of {numPages}
              </Text>
            </Box>
          </Box>
        );

      case 'text/plain':
        return (
          <Box
            border="2px dashed gray"
            height="300px"
            width="300px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <IconButton
              position="absolute"
              top="2"
              right="2"
              zIndex="10"
              colorScheme="red"
              aria-label="Delete File"
              icon={<CloseIcon />}
              size="sm"
              onClick={onDelete}
            />
            <Box height="100%" width="100%" overflow="hidden" padding="2">
              <Text fontSize="sm" whiteSpace="pre-wrap">
                {file.text}
              </Text>
            </Box>
          </Box>
        );

      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return (
          <Box
            border="2px dashed gray"
            height="300px"
            width="300px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <IconButton
              position="absolute"
              top="2"
              right="2"
              zIndex="10"
              colorScheme="red"
              aria-label="Delete File"
              icon={<CloseIcon />}
              size="sm"
              onClick={onDelete}
            />
            <Box
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text>No preview available for Word documents</Text>
            </Box>
          </Box>
        );

      default:
        return (
          <Box
            border="2px dashed gray"
            height="300px"
            width="300px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
          >
            <IconButton
              position="absolute"
              top="2"
              right="2"
              zIndex="10"
              colorScheme="red"
              aria-label="Delete File"
              icon={<CloseIcon />}
              size="sm"
              onClick={onDelete}
            />
            <Text>No preview available for this file type</Text>
          </Box>
        );
    }
  };

  return GetFilePreview();
};
const CreateAd = () => {
  const { colorMode } = useColorMode();
  const [isVerify, setIsVerify] = useState(false);
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const borderOtp = useColorModeValue('brand.500', 'gray.100');
  const borderOtpInact = useColorModeValue('brand.300', 'gray.300');
  const packColor = useColorModeValue('navy', 'white');
  const bgColor = colorMode === 'dark' ? 'navy.900' : 'gray.100';

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const bg = useColorModeValue('gray.100', 'navy.700');
  const bgUpload = useColorModeValue('gray.100', 'navy.800');
  const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
  const { success, error } = useAlert();
  const history = useHistory();
  const [login, setLogin] = useState(false);
  const userData = localStorage.getItem('user-token');
  const user = JSON.parse(userData);
  const [orientation, setOrientation] = useState('horizontal');
  const [timer, setTimer] = useState(300); // 5 minutes in seconds
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryLabel, setSelectedCategoryLabel] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');

  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [selectedSubcategoryLabel, setSelectedSubcategoryLabel] = useState('');
  const currentUser = JSON.parse(userData);
  const [pinId, setPinId] = useState();
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const [selectedCityId, setSelectedCityId] = useState('');
  const bgBar = colorMode === 'dark' ? 'purple.500' : 'navy.900';

  const [secteurs, setSecteurs] = useState([]);
  const [selectedSecteur, setSelectedsecteur] = useState('');
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(33.33);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [tableFields, setTableFields] = useState([]);
  const [tableSubFields, setTableSubFields] = useState([]);
  const [tableFieldsCat, setTableFieldsCat] = useState([]);
  const [adId, setAdId] = useState();
  const [admines, setAdmines] = useState([]);
  const [shop, setShop] = useState(null);
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const verifOptions = {
    loop: true,
    autoplay: true,
    animationData: verifAnim,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/users/admin/all`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        const users = res.data;
        const admins = users.filter(user => user.isAdmin);
        setAdmines(admins);
      } catch (error) {
        console.error(error);
        // Handle the error as needed (e.g., show an error message to the user)
      }
    };

    fetchData(); // Call the asynchronous function here
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      axios
        .get(
          `${process.env.REACT_APP_API}/customFields/get/category/${selectedCategory}`
        )
        .then(response => {
          setTableFieldsCat(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [selectedCategory]);
  useEffect(() => {
    if (selectedSubcategory) {
      axios
        .get(
          `${process.env.REACT_APP_API}/customFields/get/subcategory/${selectedSubcategory}`
        )
        .then(response => {
          setTableFields(response.data); // set the first object in the array as the state value
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [selectedSubcategory]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/categories`)
      .then(res => setCategories(res.data))
      .catch(err => console.error(err));
  }, []);
  useEffect(() => {
    if (selectedCategoryId) {
      axios
        .get(`${process.env.REACT_APP_API}/subcategories/${selectedCategoryId}`)
        .then(res => setSubcategories(res.data))
        .catch(err => console.error(err));
    }
  }, [selectedCategoryId]);
  const handleRadioChange = value => {
    console.log('Selected value:', value);
    setSelectedType(value);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/cities/test`)
      .then(res => setCities(res.data))
      .catch(err => console.error(err));
  }, []);
  useEffect(() => {
    if (selectedCityId) {
      axios
        .get(`${process.env.REACT_APP_API}/secteurs/${selectedCityId}`)
        .then(res => setSecteurs(res.data))
        .catch(err => console.error(err));
    }
  }, [selectedCityId]);
  const [price, setPrice] = useState();
  const [ad, setAd] = useState({
    userId: user._id,
    firstName: user?.firstName,
    lastName: user.lastName,
    phone: user.phone,
    categoryName: '',
    subcategoryName: '',
    name: '',
    type: 'offre',
    secteur: '',
    city: '',
    region: '',
    price: 0,
    description: '',
    adPictures: [],
    adFile: '',
    adVideo: '',
    status: t('En cours de Validation'),
    userProfilePicture: user.profilePicture,
    ProPart: user.ProPart,
    shop: '',
    idShop: '',
  });
  const fetchShopByName = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/shops/shop/name/${user.shop}`
      );
      const shopData = response.data;
      console.log('shopData', shopData);
      setAd(prevAd => ({
        ...prevAd,
        userId: user?._id,
        firstName: user.firstName,
        lastName: user?.lastName,
        phone:
          user?.ProPart === 'part' ? user?.phone : shopData?.contactInfo?.phone,
        userProfilePicture:
          user.ProPart === 'part' ? user?.profilePicture : shopData?.logo,
        shop: user?.ProPart === 'part' ? '' : shopData?.name,
        idShop: user?.ProPart === 'part' ? '' : shopData?._id,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user?.shop) {
      fetchShopByName();
    }
  }, [user?.shop]);

  console.log('ad', ad);
  const premium = useColorModeValue('#6f432a', 'gold');
  const [fieldsValues, setFieldsValues] = useState([]);
  const [subFieldsValues, setSubFieldsValues] = useState([]);
  const formatPhoneNumber = number => {
    // Remove leading 0
    const formattedNumber = number.replace(/^0+/, '');
    const countries = CountryList.getAll();

    // Add country code
    return selectedCountryCode + formattedNumber;
  };
  const nonZeroNumber = number => {
    // Remove leading 0
    const formattedNumber = number.replace(/^0+/, '');

    // Add country code
    return formattedNumber;
  };
  const countries = CountryList.getAll();

  const countryCodes = countries.map(country => ({
    value: country.dial_code,
    label: country.code + ' ' + country.dial_code,
  }));
  const HandleChange = e => {
    const { name, value } = e.target;

    // Conditionally update the price field if it is changed
    if (name === 'price') {
      // setPrice(e.target.value);
      setAd({
        ...ad,
        price: value,
      });
    } else {
      setAd({
        ...ad,
        [name]: value,
      });
    }
  };

  const [files, setFiles] = useState(Array.from({ length: 6 }, () => null)); // const fileInputs = useRef(new Array(6).fill(null));

  function showToastAndRedirect() {
    try {
      history.push('/');
    } catch (error) {
      console.error(error);
    }
  }

  const [proFile, setProFile] = useState(null);
  const [video, setVideo] = useState(null);
  const [adPictures, setAdPictures] = useState([]);
  const [isVerifying, setIsVerifying] = useState(false);
  const [subscriptions, setSubscriptions] = useState('');
  useEffect(() => {
    const fetchSubsciptions = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/subscriptions/category/${selectedCategoryId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        const subscriptions = res.data;
        setSubscriptions(subscriptions);
      } catch (error) {
        console.error(error);
        // Handle the error as needed (e.g., show an error message to the user)
      }
    };

    fetchSubsciptions(); // Call the asynchronous function here
  }, [selectedCategoryId]);
  const handleFileChange = (event, index) => {
    const file = event.target.files[0];

    const updatedFiles = [...files];
    updatedFiles[index] = file;
    setFiles(updatedFiles);
  };
  const handleProFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      // Check file size (2MB = 2 * 1024 * 1024 bytes)
      if (file.size > 2 * 1024 * 1024) {
        toast.error('La taille du fichier dépasse 2 Mo', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
        });
        return;
      }
  
      // If the file size is within limit, set the file
      setProFile(file);
    } else {
      toast.error('Veuillez sélectionner un fichier', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
      });
    }
  };
  useEffect(() => {
    console.log('PRO FILE', proFile);
  }, [proFile]);
  const handleVideoChange = event => {
    const videoFile = event.target.files[0];
    if (videoFile) {
      // Check file type
      if (!videoFile.type.startsWith('video/')) {
        toast.error('Veuillez sélectionner un fichier vidéo valide', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
        });
        return;
      }
  
      // Check file size (60MB = 60 * 1024 * 1024 bytes)
      if (videoFile.size > 60 * 1024 * 1024) {
        toast.error('La taille du fichier dépasse 60 Mo', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
        });
        return;
      }
  
      // Check video duration
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function() {
        // Duration in seconds
        if (video.duration > 60) {
          toast.error('La durée de la vidéo dépasse 1 minute', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'colored',
          });
          return;
        }
  
        // If all checks pass, set the video
        setVideo(videoFile);
      };
      video.src = URL.createObjectURL(videoFile);
    }
  };
  const handleChangeOtp = (e, index) => {
    if (isNaN(e.target.value)) return;
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
    if (index < 5 && e.target.value !== '') {
      const nextInput = document.getElementById(`input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
    console.log('otp', otp);
  };

  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace' && index > 0) {
      const newOtp = [...otp];
      newOtp[index - 1] = '';
      setOtp(newOtp);
      const prevInput = document.getElementById(`input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };
  const inputOtp = otp.join('');
  console.log('inputOtp', inputOtp);
  const handleSendVerification = async () => {
    try {
      const response = await axios.post(
        'https://jindoo.ma:6001/users/verification/sendCode',
        {
          phoneNumber: selectedCountryCode + nonZeroNumber(phoneNumber),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json', // Update the Content-Type header to 'application/json'
          },
        }
      );
      console.log(response.data);
      const otpResponseObj = JSON.parse(response.data.otpResponse);
      console.log('otpResponseObj', otpResponseObj);
      setPinId(otpResponseObj.pinId);
      console.log('pinId', otpResponseObj.pinId);
      setIsCodeSent(true);
      setIsVerify(true);
      startTimer();
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  };


  const startTimer = () => {
    let timeLeft = 300; // 5 minutes in seconds
    const interval = setInterval(() => {
      timeLeft--;
      setTimer(timeLeft);
      if (timeLeft === 0) {
        clearInterval(interval);
        setIsCodeSent(false);
      }
    }, 1000);
  };

  console.log('isCodeSent', isCodeSent);

  const verifyCode = async () => {
    const timeoutDuration = 10000; // 10 seconds

    try {
        const timeoutPromise = new Promise(resolve => {
            setTimeout(() => {
                resolve('timeout');
            }, timeoutDuration);
        });

        const responsePromise = axios.post(
            'https://jindoo.ma:6001/users/verification/verifyCode',
            {
                pinId: pinId,
                pin: inputOtp,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        const response = await Promise.race([responsePromise, timeoutPromise]);

        if (response === 'timeout') {
            throw new Error('Verification process timed out');
        }

        if (response.status === 200) {
            const { verified, pinError, attemptsRemaining } = response.data.verifyResponse;

            if (verified) {
                // Verification successful
                console.log('Verification successful');
                toast.success('Téléphone vérifié !', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                return 'success';
            } else if (pinError === 'WRONG_PIN') {
                // OTP is incorrect
                console.log(`Incorrect OTP. Attempts remaining: ${attemptsRemaining}`);
                toast.error(`Code de vérification incorrect. Il vous reste ${attemptsRemaining} tentatives`, {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                return 'pending';
            } else {
                // Other issues (if any)
                console.log('Verification failed for an unknown reason');
                toast.error('Échec de la vérification. Veuillez réessayer plus tard', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                return 'pending';
            }
        } else {
            // Handle unexpected status codes
            console.log('Received an error response:', response.status);
            toast.error('An error occurred during verification', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            return 'error';
        }
    } catch (error) {
        console.error('An error occurred:', error);
        toast.error('An unexpected error occurred during verification. Please try again later.', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });
        return 'error';
    }
};

  const makeOrder = async (id, adId, promo, reposts, price) => {
    try {
      const response = await axios.post(
        'https://jindoo.ma:6001/orders/order',
        {
          idSubscription: id,
          idUser: user._id,
          price: price,
          idAd: adId,
          promo: promo,
          reposts: reposts,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('order', response.data.order.generatedId); // Check the response from the server

      // Assuming the order was created successfully, redirect to the cart page
      history.push(`/ad/order/${response.data.order.generatedId}`);
    } catch (error) {
      // Handle any errors that occur during the HTTP request
      console.error('An error occurred:', error);
    }
  };
  const [dayIndices, setDayIndices] = useState([]); // State for day indices
  const [repostIndices, setRepostIndices] = useState([]); // State for repost indices
  const [priceIndices, setPriceIndices] = useState([]); // State for price indices

  useEffect(() => {
    // Initialize dayIndices and repostIndices based on subscriptions length
    setDayIndices(new Array(subscriptions.length).fill(0));
    setRepostIndices(new Array(subscriptions.length).fill(0));
    setPriceIndices(new Array(subscriptions.length).fill(0));
  }, [subscriptions]); // State for repost indices

  const handleAdd = index => {
    const updatedSubscription = subscriptions[index];

    if (
      updatedSubscription &&
      updatedSubscription.days &&
      updatedSubscription.reposts &&
      updatedSubscription.price &&
      updatedSubscription.price
    ) {
      const daysArray = updatedSubscription.days; // Array of days
      const repostsArray = updatedSubscription.reposts; // Array of reposts
      const priceArray = Object.keys(updatedSubscription.price); // Array of price indices

      let newIndex = (dayIndices[index] + 1) % daysArray.length; // Calculate the new index for days
      let newRepostIndex = (repostIndices[index] + 1) % repostsArray.length; // Calculate the new index for reposts
      let newPriceIndex = (priceIndices[index] + 1) % priceArray.length; // Calculate the new index for price

      setDayIndices(prevIndices => {
        const newDayIndices = [...prevIndices];
        newDayIndices[index] = newIndex; // Update the current day index for the specific subscription
        return newDayIndices;
      });

      setRepostIndices(prevIndices => {
        const newRepostIndices = [...prevIndices];
        newRepostIndices[index] = newRepostIndex; // Update the current repost index for the specific subscription
        return newRepostIndices;
      });

      setPriceIndices(prevIndices => {
        const newPriceIndices = [...prevIndices];
        newPriceIndices[index] = newPriceIndex; // Update the current price index for the specific subscription
        return newPriceIndices;
      });
    }
  };

  const handleSubtract = index => {
    const updatedSubscription = subscriptions[index];

    if (
      updatedSubscription &&
      updatedSubscription.days &&
      updatedSubscription.reposts &&
      updatedSubscription.price &&
      updatedSubscription.price
    ) {
      const daysArray = updatedSubscription.days; // Array of days
      const repostsArray = updatedSubscription.reposts; // Array of reposts
      const priceArray = Object.keys(updatedSubscription.price); // Array of price indices

      let newIndex =
        (dayIndices[index] - 1 + daysArray.length) % daysArray.length; // Calculate the new index for days
      let newRepostIndex =
        (repostIndices[index] - 1 + repostsArray.length) % repostsArray.length; // Calculate the new index for reposts
      let newPriceIndex =
        (priceIndices[index] - 1 + priceArray.length) % priceArray.length; // Calculate the new index for price

      setDayIndices(prevIndices => {
        const newDayIndices = [...prevIndices];
        newDayIndices[index] = newIndex; // Update the current day index for the specific subscription
        return newDayIndices;
      });

      setRepostIndices(prevIndices => {
        const newRepostIndices = [...prevIndices];
        newRepostIndices[index] = newRepostIndex; // Update the current repost index for the specific subscription
        return newRepostIndices;
      });

      setPriceIndices(prevIndices => {
        const newPriceIndices = [...prevIndices];
        newPriceIndices[index] = newPriceIndex; // Update the current price index for the specific subscription
        return newPriceIndices;
      });
    }
  };

  const handleOnClick = async (e) => {
    setIsVerifying(true); // Show spinner while verifying

    try {
        const result = await verifyCode();

        if (result === 'success') {
            const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

            updateUserPhone(formattedPhoneNumber)
                .then((updatedUser) => {
                    toast.success('Phone updated', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.log('User phone updated:', updatedUser);
                })
                .catch((error) => {
                    toast.error('Error updating user phone number', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.error('Error updating user phone number:', error);
                });

            console.log('Phone Verified');
      

            currentUser.isPhoneVerified = true;
            localStorage.setItem('user-token', JSON.stringify(currentUser));

            setIsVerify(false);
          
        } else if (result === 'error') {
            toast.error('An error occurred during verification', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        } else if (result === 'expired') {
            toast.error('Verification code expired', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        }
    } catch (error) {
        if (error.message === 'Verification process timed out') {
            toast.error('Verification process timed out', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        } else {
            console.error('Error occurred during verification:', error);
            // Handle other error cases if needed
        }
    } finally {
        setIsVerifying(false);
        
    }
};


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 872) {
        setOrientation('vertical');
      } else {
        setOrientation('horizontal');
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call the handleResize function initially to set the correct orientation on component mount
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // images.forEach(element => {
  // console.log(element.src);
  // })
  const handleDelete = index => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleDragStart = (event, id) => {
    event.dataTransfer.setData('text/plain', id);
  };

  const handleDragOver = event => {
    event.preventDefault();
  };
  const [selectedType, setSelectedType] = useState(
    selectedCategoryLabel === "Demande d'emploi et stages" ||
      selectedCategoryLabel === "Offres d'emploi et stages"
      ? null
      : ad.type
  );
  useEffect(() => {
    setSelectedType(
      selectedCategoryLabel === "Demande d'emploi et stages" ||
        selectedCategoryLabel === "Offres d'emploi et stages"
        ? null
        : ad.type
    );
  }, [selectedCategoryLabel, ad.type]);

  console.log('selectedType', selectedType);
  const handleDrop = (event, targetIndex) => {
    event.preventDefault();

    const draggedFileIndex = event.dataTransfer.getData('text/plain');
    const draggedFile = files[draggedFileIndex];

    if (draggedFileIndex !== targetIndex) {
      const newFiles = [...files];
      newFiles.splice(draggedFileIndex, 1); // Remove the dragged file from its original position
      newFiles.splice(targetIndex, 0, draggedFile); // Insert the dragged file at the target index

      setFiles(newFiles);
    }
  };

  const handleFieldsChange = (fieldId, fieldType, e) => {
    let value;
    if (fieldType === 'radio') {
      value = e;
    } else if (fieldType === 'multi') {
      value = Array.from(e);
    } else {
      value = e.target.value;
    }
    setFieldsValues(prevFieldsValues => {
      const updatedValues = [...prevFieldsValues];
      const index = updatedValues.findIndex(item => item.field_id === fieldId);
      if (index >= 0) {
        updatedValues[index].value = value;
      } else {
        updatedValues.push({
          ad_id: ad._id,
          field_id: fieldId,
          field_name: e.target?.name || '',
          value: value,
        });
      }
      console.log(updatedValues);
      return updatedValues;
    });
  };

  useEffect(() => {
    console.log(fieldsValues);
  }, [fieldsValues]);
  const matchingField = tableFields.filter(field => {
    const matchingValue = fieldsValues.find(
      item => item.field_id === field._id
    );
    return matchingValue !== undefined;
  });
  useEffect(() => {
    fieldsValues.forEach(f => {
      if (f.value) {
        axios
          .get(
            `${process.env.REACT_APP_API}/subCustomFields/get/customField/value/${f.value}/${selectedSubcategory}`
          )
          .then(response => {
            setTableSubFields(prevSubFields => {
              // Filter out existing subfield values to avoid duplicates
              const filteredSubFields = prevSubFields.filter(
                subField =>
                  !response.data.some(
                    newSubField => newSubField.id === subField.id
                  )
              );
              // Merge the filtered previous subfield values with the new ones
              return [...filteredSubFields, ...response.data];
            });
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  }, [fieldsValues]);

  const handleSubFieldsChange = (fieldId, fieldType, e) => {
    let value;
    if (fieldType === 'radio' || fieldType === 'select') {
      value = e.target.value;
    } else if (fieldType === 'multi') {
      value = Array.from(e);
    } else {
      value = e.target.value;
    }
    setSubFieldsValues(prevFieldsValues => {
      const updatedValues = [...prevFieldsValues];
      const index = updatedValues.findIndex(item => item.field_id === fieldId);
      if (index >= 0) {
        updatedValues[index].value = value;
      } else {
        updatedValues.push({
          ad_id: ad._id,
          field_id: fieldId,
          field_name: e.target?.name || '',
          value: value,
        });
      }
      console.log(updatedValues);
      return updatedValues;
    });
  };
  const [selectedCountryCode, setSelectedCountryCode] = useState('+212');

  const [adCount, setAdCount] = useState(null);
  const uploadVideo = async video => {
    if (video) {
      const videoRef = ref(storage, `adVideos/${ad.name}/${video.name + v4()}`);
      await uploadBytes(videoRef, video);
      const videoURL = await getDownloadURL(videoRef);
      return videoURL;
    }
    return null;
  };

  const uploadFile = async file => {
    if (file) {
      const fileRef = ref(storage, `adFiles/${ad.name}/${file.name + v4()}`);
      await uploadBytes(fileRef, file);
      const fileURL = await getDownloadURL(fileRef);
      return fileURL;
    }
    return null;
  };
  const fetchUserAds = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/${currentUser._id}/count`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      // console.log('adcount',response.data.adCount);
      // console.log('user ads', currentUser.nbrAds);
      setAdCount(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const add = async (fieldsValues, subfieldsValues) => {
    try {
      const formData = new FormData();
      const promises = [];
      formData.append('userId', ad?.userId);
      formData.append('firstName', ad?.firstName);
      formData.append('lastName', ad?.lastName);
      formData.append('phone', ad?.phone);
      formData.append('name', ad?.name);
      formData.append('type', selectedType);
      formData.append('secteur', selectedSecteur);
      formData.append('city', selectedCity);
      formData.append('region', selectedRegion);
      formData.append('subcategoryName', selectedSubcategory);
      formData.append('subcategoryLabel', selectedSubcategoryLabel);
      formData.append('categoryName', selectedCategory);
      formData.append('categoryLabel', selectedCategoryLabel);
      formData.append('price', ad?.price);
      formData.append('description', ad?.description);
      formData.append('userProfilePicture', ad?.userProfilePicture);
      formData.append('status', ad?.status);
      formData.append('shop', ad?.shop);
      formData.append('idShop', ad?.idShop);
      formData.append('ProPart', ad?.ProPart);
      console.log('formdata', formData);
      const uploadPromises = files.map(async element => {
        if (element) {
          // Check if the file is not empty
          const storageRef = ref(
            storage,
            `adPictures/${ad.name}/${element + v4()}`
          );
          await uploadBytes(storageRef, element);
          const downloadURL = await getDownloadURL(storageRef);
          formData.append('adPictures', downloadURL);
        }
      });

      await Promise.all(uploadPromises);
      // Upload video
      const videoURL = await uploadVideo(video);
      if (videoURL) {
        formData.append('adVideo', videoURL);
      }

      // Upload file
      const fileURL = await uploadFile(proFile);
      if (fileURL) {
        formData.append('adFile', fileURL);
      }
      const configuration = {
        method: 'put',
        url: `${process.env.REACT_APP_API}/ads`,
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': `multipart/form-data; charset=utf-8`, // Specify UTF-8 charset
        },
      };

      const result = await axios(configuration);
      const adToken = result.data;
      setAdId(adToken._id);

      // Update shop's ads array with the ad's ID
      const data = { adId: adToken._id };
      if (ad.idShop) {
        const configShop = {
          method: 'patch',
          url: `${process.env.REACT_APP_API}/shops/shop/${ad.idShop}/ads`,
          data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': `application/json`,
          },
        };

        const responseShop = await axios(configShop);
        console.log('shop updated ads', responseShop.data.ads);
      }

      const notifications = {
        // recipient: userId, // replace with actual recipient ID
        sender: 'system',
        message: `${t('Nouvelle annonce créee par')} ${currentUser.firstName} ${
          currentUser.lastName
        } ${t('et nécessitant une vérification.')}`,
        type: 'newAddAdmin', // specify the type of notification
        adId: adToken._id, // adId
      };
      const notificationData = admines.map(admin => ({
        recipient: admin._id,
        ...notifications,
      }));

      notificationData.forEach(async notification => {
        socket.emit('sendNotification', notification);
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API}/notifications`,
            notification,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );

          const createdNotification = response.data;
        } catch (error) {
          console.error(error);
        }
      });

      const fieldValues = tableFields.map(field => {
        const foundFieldValue = fieldsValues.find(
          item => item.field_id === field._id
        );
        return {
          ad_id: adToken._id,
          field_id: field._id,
          field_name: field.name,
          value: foundFieldValue ? foundFieldValue.value : '',
        };
      });

      for (const fieldValue of fieldValues) {
        try {
          const response = axios.post(
            `${process.env.REACT_APP_API}/customFieldsValues/add/new`,
            fieldValue,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          console.log(response.data);
          // show a success message to the user
          // toast.success('Field value created successfully!', {
          //   position: 'top-center',
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: 'colored',
          // });
        } catch (error) {
          console.log(error);
          // show an error message to the user
          toast.error('Error creating field value!', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      }

      const fieldValuesCat = tableFieldsCat.map(field => {
        const foundFieldValue = fieldsValues.find(
          item => item.field_id === field._id
        );
        return {
          ad_id: adToken._id,
          field_id: field._id,
          field_name: field.name,
          value: foundFieldValue ? foundFieldValue.value : '',
        };
      });
      console.log(fieldValuesCat);
      for (const fieldValueCat of fieldValuesCat) {
        try {
          const response = axios.post(
            `${process.env.REACT_APP_API}/customFieldsValues/add/new`,
            fieldValueCat,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          console.log(response.data);
          // show a success message to the user
          // toast.success('Field value created successfully!', {
          //   position: 'top-center',
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: 'colored',
          // });
        } catch (error) {
          console.log(error);
          // show an error message to the user
          toast.error('Error creating field value!', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      }

      const subfieldValues = tableSubFields.map(field => {
        const foundFieldValue = subfieldsValues.find(
          item => item.field_id === field._id
        );
        return {
          ad_id: adToken._id,
          field_id: field._id,
          field_name: field.name,
          value: foundFieldValue ? foundFieldValue.value : '',
        };
      });
      for (const subfield of subfieldValues) {
        try {
          const response = axios.post(
            `${process.env.REACT_APP_API}/subCustomFieldsValues/add/new`,
            subfield,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          console.log(response.data);
          // show a success message to the user
          // toast.success('Field value created successfully!', {
          //   position: 'top-center',
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: 'colored',
          // });
        } catch (error) {
          console.log(error);
          // show an error message to the user
          toast.error('Error creating field value!', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      }
      if (!adToken) {
        error('Error', 'Un probleme est survenu ');
        return;
      }
    } catch (error) {
      console.error('Error during add process:', error);
      // Handle the error appropriately
    }
  };
  const adData = {
    name: document.getElementById('name')?.value,
    description: document.getElementById('description')?.value,
    userId: user._id,
    firstName: user.ProPart === 'part' ? user?.firstName : shop?.name,
    lastName: user.ProPart === 'part' ? user?.lastName : '',
    phone: user.ProPart === 'part' ? user?.phone : shop?.contactInfo.phone,
    categoryName: document.getElementById('category')?.value,
    subcategoryName: document.getElementById('subcategory')?.value,
    secteur: document.getElementById('secteur')?.value,
    city: document.getElementById('city')?.value,
    region: document.getElementById('region')?.value,
    type: document.getElementById('type')?.value,
    price: 0,
    adPictures: '',
    status: 'Brouillon',
    userProfilePicture:
      user.ProPart === 'part' ? user?.profilePicture : shop?.logo,

    // Other fields
  };
  const [draftId, setDraftId] = useState(null);

  const saveDraft = async adData => {
    try {
      if (!draftId) {
        const configuration = {
          method: 'post',
          url: `${process.env.REACT_APP_API}/ads/draft/save`,
          data: adData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };

        await axios(configuration)
          .then(result => {
            const adToken = result.data;
            setDraftId(result.data._id);

            if (!adToken) {
              error('Error', 'Un probleme est survenu ');
              return;
            }
          })
          .catch(error => {
            error = new Error();
          });
      } else {
        // Update the existing draft
        const configuration = {
          method: 'put',
          url: `${process.env.REACT_APP_API}/ads/draft/update/${draftId}`,
          data: adData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };

        await axios(configuration)
          .then(result => {
            const adToken = result.data;

            if (!adToken) {
              error('Error', 'Un probleme est survenu ');
              return;
            }
          })
          .catch(error => {
            error = new Error();
          });
      }
    } catch (error) {
      console.error(error);
    }
  };
  // Get all links within the app

  useEffect(() => {
    if (adCount?.adCount < currentUser.nbrAds) {
      const interval = setInterval(() => {
        saveDraft(adData);
      }, 5000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(interval);
    }
  }, [adData]);

  // Add beforeunload event listener to save draft when user leaves the current view
  const updateUserPhone = async phone => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/users/updatePhone/${user._id}`,
        { phone },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json', // Update the Content-Type header to 'application/json'
          },
        }
      );

      if (response.status === 200) {
        // Return the updated user object
        const updatedUser = response.data;
        localStorage.setItem('user-token', JSON.stringify(updatedUser)); // Assuming the updated user object contains a 'token' property

        return updatedUser;
      } else {
        throw new Error('Failed to update user phone number');
      }
    } catch (error) {
      console.error('Error updating user phone number:', error);
      throw error;
    }
  };
  const [selectedOptions, setSelectedOptions] = useState([]);
  const steps = [
    {
      title: 'Etape 1 ',
      description: 'Classification de votre annonce',
      isComplete: step >= 1,
    },
    {
      title: 'Etape 2 ',
      description: 'Identification de votre annonce',
      isComplete: step >= 2,
    },
    {
      title: 'Etape 3',
      description: 'Localisation de votre annonce',
      isComplete: step >= 3,
    },
  ];

  // Add a state to toggle the dropdown menu
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    user.phone ? user.phone.replace('+212', '0') : ''
  );
  const handlePhoneNumberChange = e => {
    user.phone = e.target.value;
  };
  const handleSubmit = async e => {
    e.preventDefault();

    if (!selectedCity.trim()) {
      toast.error('Donner une ville à votre annonce!', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }

    setStep(step + 1);
    if (step === 4) {
      setStep(4);
    } else {
      setStep(step + 1);
    }

    // Rest of the code...
    await add(fieldsValues, subFieldsValues);
  };

  useEffect(() => {
    fetchUserAds();
  }, []);

  return (
    <Card padding="20px" mt={{ base: '80px', md: '10px' }}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Flex
        direction="column"
        w={{ base: '100%', md: '100%' }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: 'auto', lg: 'unset' }}
        me="auto"
        mb={{ base: '20px', md: 'auto' }}
      >
        <Text
          mb={2}
          color={textColor}
          align="start"
          fontSize="2xl"
          fontWeight="600"
        >
          {adCount?.adCount >= currentUser?.nbrAds
            ? t(`Vous avez atteint la limite maximale du nombre d'annonces.`)
            : t('Ajoutez une annonce gratuitement')}
          {}
        </Text>
        {adCount?.adCount >= currentUser?.nbrAds && (
          <Text mb={-20} color={textColor} align="start">
            Veuillez passer au plan Pro pour continuer à publier davantage
            d'annonces.
          </Text>
        )}

        {adCount?.adCount >= currentUser?.nbrAds ? (
          <Memberships />
        ) : (
          <>
            <Box textAlign="left">
              <Stepper
                orientation={orientation}
                activeStep={step - 1}
                colorScheme="purple"
                marginBottom="5%"
                index={step - 1}
              >
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>

                    <StepSeparator />
                  </Step>
                ))}
              </Stepper>
            </Box>

            <FormControl onSubmit={e => handleSubmit(e)}>
              {user.phone === '060000000' ||
              user.phone === '+21260000000' ||
              user.isPhoneVerified === false ||
              !user.hasOwnProperty('isPhoneVerified')   ? (
                <Box
                  borderWidth="1px"
                  rounded="lg"
                  shadow="sm"
                  p={6}
                  m="20px auto"
                  as="form"
                >
                  <Heading
                    textAlign="center"
                    fontWeight="semibold"
                    mb={4}
                    fontSize="2xl"
                  >
                    {t('Ajouter un numéro de téléphone valide')}
                    <Tooltip
                      label={t(
                        'Pour ajouter de nouvelles annonces, veuillez fournir un numéro de téléphone valide.'
                      )}
                      fontSize="md"
                    >
                      <InfoIcon color="brand.400" ml={2} pb={1} />
                    </Tooltip>
                  </Heading>

                { isVerify === false  && 
                  <Stack spacing={4}>
                      <InputGroup>
                        <Select
                          placeholder={t('Indicatif pays')}
                          size="lg"
                          fontSize="sm"
                          w="30%"
                          variant="outline"
                          value={selectedCountryCode}
                          onChange={e => setSelectedCountryCode(e.target.value)}
                        >
                          {countryCodes.map(country => (
                            <option key={country.value} value={country.value}>
                              {country.label}
                            </option>
                          ))}
                        </Select>
                        <Input
                          isRequired
                          variant="outline"
                          fontSize="sm"
                          ms={2}
                          type="tel"
                          placeholder={t('Numéro de téléphone')}
                          size="lg"
                          value={phoneNumber}
                          onChange={e => setPhoneNumber(e.target.value)}
                        />
                        <Button
                          ml={4}
                          size="lg"
                          w={'400px'}
                          variant="brand"
                          onClick={handleSendVerification}
                          isDisabled={!phoneNumber || phoneNumber.length < 10}
                        >
                          {t('Vérifier le numéro')}
                        </Button>
                      </InputGroup>
                    </Stack>
                }
                    
                
                  {isVerify === true && (
                    <>
                      <Lottie options={verifOptions} height={300} width={400} />
                      <Box mb="15px">
                        {' '}
                        <Box>
                          {' '}
                          <Box>
                            {' '}
                            <Text fontSize={25}>
                              Entrer le code de Verification
                            </Text>
                          </Box>
                        </Box>{' '}
                        <Text fontSize={15}>
                          Veuillez saisir le code de vérification de 6 chiffres
                          que nous avons envoyé au : {phoneNumber}
                        </Text>
                      </Box>
                      <Card>
                        {' '}
                        <FormControl>
                          <Flex
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            textAlign="center"
                            w="100%"
                            p={4}
                          >
                            <HStack justifyContent="center" spacing={2}>
                              {otp.map((digit, index) => (
                                <Input
                                  key={index}
                                  value={digit}
                                  onChange={e => handleChangeOtp(e, index)}
                                  onKeyDown={e => handleBackspace(e, index)}
                                  maxLength={1}
                                  width="3rem"
                                  height="3rem"
                                  textAlign="center"
                                  fontSize="xl"
                                  borderRadius="md"
                                  borderWidth="1px"
                                  borderColor={borderOtpInact}
                                  _focus={{
                                    borderColor: borderOtp,
                                    boxShadow: '0 0 0 2px rgba(0, 0, 255, 0.2)',
                                  }}
                                  _hover={{
                                    borderColor: borderOtp,
                                  }}
                                />
                              ))}
                            </HStack>

                            {/* Uncomment below to show the timer */}
                            <Box mt={2} fontSize="sm" color="gray.600">
                              {Math.floor(timer / 60)}:
                              {timer % 60 < 10 ? `0${timer % 60}` : timer % 60} min
                            </Box>
                          </Flex>
                        </FormControl>
                      </Card>
                      <Box mt={5} w="100%">
                        <Button
                          w="90%"
                          onClick={e => handleOnClick(e)}
                          colorScheme="purple"
                          variant="solid"
                        >
                          {' '}
                          {isVerifying ? 'Verifying...' : t('Ajouter')}
                        </Button>
                        <Text pt={2} color="gray.500">
                          Vous n'avez pas reçu le code ?{' '}
                          <Link
                            color="teal.500"
                            onClick={handleSendVerification}
                          >
                            Renvoyer le code
                          </Link>
                          .
                        </Text>
                      </Box>
                    </>
                  )}
                </Box>
              ) : null}

              <Box
                borderWidth="1px"
                rounded="lg"
                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                p={6}
                m="10px auto"
                as="form"
              >
                {step === 1 ? (
                  <>
                    <Heading
                      w="100%"
                      textAlign={'center'}
                      fontWeight="normal"
                      mb="2%"
                    >
                      {t('Etape 1: Classification de votre annonce ')}
                      <Tooltip
                        label="Sélectionnez la catégorie principale, puis une sous-catégorie spécifique, et remplissez les champs requis pour mieux décrire votre annonce."
                        fontSize="md"
                      >
                        <InfoIcon color="brand.400" ml={2} pb={1} />
                      </Tooltip>
                    </Heading>

                    <FormControl>
                      <SimpleGrid
                        columns={{ base: 1, md: 2 }}
                        gap="20px"
                        mb={{ base: '20px', xl: '0px' }}
                      >
                        <Box height="90px">
                          <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="md"
                            fontWeight="500"
                            color={textColor}
                            mb="8px"
                          >
                            {t(`Choisir une categorie`)}{' '}
                            <Text color={brandStars}> * </Text>{' '}
                          </FormLabel>{' '}
                          <Select
                            id="category"
                            name="categoryName"
                            isRequired={true}
                            fontSize="sm"
                            mb="24px"
                            size="lg"
                            variant="auth"
                            onChange={e => {
                              const selectedCategoryId =
                                e.target.options[e.target.selectedIndex].dataset
                                  .id;
                              const selectedCategory = categories.find(
                                category => category._id === selectedCategoryId
                              );
                              setSelectedSubcategory(null);
                              setSelectedCategory(selectedCategory.name);
                              setSelectedCategoryId(selectedCategoryId);
                              setSelectedCategoryLabel(selectedCategory.label);
                            }}
                            placeholder={t(`Choisir une categorie`)}
                          >
                            {categories.map(category => (
                              <option
                                key={category._id}
                                value={category.name}
                                name={category.label}
                                data-id={category._id}
                              >
                                {category.label}
                              </option>
                            ))}
                          </Select>
                        </Box>
                        <Box height="90px">
                          <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="md"
                            fontWeight="500"
                            color={textColor}
                            mb="8px"
                          >
                            {t(`Choisir une sous-categorie`)}{' '}
                            <Text color={brandStars}> * </Text>{' '}
                          </FormLabel>{' '}
                          <Select
                            id="category"
                            name="categoryName"
                            isRequired={true}
                            fontSize="sm"
                            mb="24px"
                            size="lg"
                            variant="auth"
                            onChange={e => {
                              setSelectedSubcategory(e.target.value);
                              setSelectedSubcategoryLabel(
                                e.target.options[e.target.selectedIndex].text
                              );
                            }}
                            placeholder={t(`Choisir une sous-categorie`)}
                          >
                            {' '}
                            {subcategories.map(subcategory => (
                              <option
                                key={subcategory._id}
                                value={subcategory.name}
                                name={subcategory.label}
                              >
                                {' '}
                                {subcategory.label}{' '}
                              </option>
                            ))}{' '}
                          </Select>
                        </Box>
                        {selectedCategoryLabel ===
                          "Demande d'emploi et stages" ||
                        selectedCategoryLabel ===
                          "Offres d'emploi et stages" ? null : (
                          <Box height="90px">
                            <FormLabel
                              ms="4px"
                              fontSize="md"
                              fontWeight="500"
                              color={textColor}
                              display="flex"
                            >
                              {t(`Choisissez le type de votre annonce`)}{' '}
                              <Text color={brandStars}> * </Text>{' '}
                            </FormLabel>
                            <RadioGroup
                              name="type"
                              variant="auth"
                              onChange={handleRadioChange}
                              value={selectedType}
                              mb="20px"
                            >
                              <Stack direction="row">
                                <Radio
                                  colorScheme="red"
                                  size="lg"
                                  value="offre"
                                >
                                  {t('Offre')}
                                </Radio>
                                <Radio
                                  colorScheme="green"
                                  size="lg"
                                  value="demande"
                                >
                                  {t('Demande')}
                                </Radio>
                              </Stack>
                            </RadioGroup>{' '}
                          </Box>
                        )}
                        {selectedSubcategory && (
                          <>
                            {tableFields.map(field => (
                              <>
                                <Box height="90px" key={field._id} m={2}>
                                  <FormLabel
                                    ms="4px"
                                    fontSize="md"
                                    fontWeight="500"
                                    color={textColor}
                                    display="flex"
                                  >
                                    {field.name}
                                  </FormLabel>
                                  <FormControl isRequired={true}>
                                    {field.type === 'text' && (
                                      <InputGroup size="md">
                                        <Input
                                          name={field.name}
                                          fontSize="sm"
                                          placeholder="Enter text"
                                          mb="24px"
                                          size="lg"
                                          variant="auth"
                                          value={
                                            fieldsValues.find(
                                              item =>
                                                item.field_id === field._id
                                            )?.value
                                          }
                                          onChange={value =>
                                            handleFieldsChange(
                                              field._id,
                                              field.type,
                                              value
                                            )
                                          }
                                        />
                                      </InputGroup>
                                    )}
                                    {field.type === 'textarea' && (
                                      <Textarea
                                        fontSize="sm"
                                        placeholder="Enter text"
                                        mb="24px"
                                        size="lg"
                                        variant="auth"
                                        name={field.name}
                                        value={
                                          fieldsValues.find(
                                            item => item.field_id === field._id
                                          )?.value
                                        }
                                        onChange={value =>
                                          handleFieldsChange(
                                            field._id,
                                            field.type,
                                            value
                                          )
                                        }
                                      />
                                    )}
                                    {field.type === 'radio' && (
                                      <RadioGroup
                                        flexWrap="wrap"
                                        variant="auth"
                                        name={field.name}
                                        value={
                                          fieldsValues.find(
                                            item => item.field_id === field._id
                                          )?.value
                                        }
                                        onChange={value =>
                                          handleFieldsChange(
                                            field._id,
                                            field.type,
                                            value
                                          )
                                        }
                                        mb="24px"
                                        size="lg"
                                      >
                                        <Wrap direction="row" flexWrap="wrap">
                                          {field.options.map(option => (
                                            <WrapItem key={option}>
                                              <Radio value={option}>
                                                {option}
                                              </Radio>
                                            </WrapItem>
                                          ))}
                                        </Wrap>
                                      </RadioGroup>
                                    )}

                                    {field.type === 'select' && (
                                      <Select
                                        placeholder="Select option"
                                        name={field.name}
                                        value={
                                          fieldsValues.find(
                                            item => item.field_id === field._id
                                          )?.value
                                        }
                                        onChange={value =>
                                          handleFieldsChange(
                                            field._id,
                                            field.type,
                                            value
                                          )
                                        }
                                        fontSize="sm"
                                        mb="24px"
                                        size="lg"
                                        variant="auth"
                                      >
                                        {field.options.map(option => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </Select>
                                    )}
                                    {field.type === 'multi' && (
                                      <FormControl isRequired={true}>
                                        <Box position="relative">
                                          <InputGroup>
                                            <Input
                                              placeholder="Select options"
                                              variant="auth"
                                              value={selectedOptions.join(', ')}
                                              readOnly
                                              cursor="pointer"
                                              onClick={() =>
                                                setIsDropdownOpen(
                                                  !isDropdownOpen
                                                )
                                              }
                                              pr="3rem" // Add padding-right to make space for the icon
                                            />
                                            <InputRightElement
                                              pointerEvents="none"
                                              top="50%" // Vertically align the icon
                                              right="0.5rem" // Adjust the positioning of the icon
                                              transform="translateY(-50%)" // Vertically center the icon
                                              cursor="pointer"
                                            >
                                              <Icon as={FiChevronDown} />
                                            </InputRightElement>
                                          </InputGroup>

                                          {isDropdownOpen && (
                                            <Box
                                              position="absolute"
                                              top="100%"
                                              left={0}
                                              zIndex={1}
                                              width="100%"
                                              bg={bg}
                                              p={2}
                                              borderWidth={1}
                                              boxShadow="sm"
                                            >
                                              <CheckboxGroup
                                                colorScheme="blue"
                                                value={selectedOptions}
                                                onChange={values => {
                                                  handleFieldsChange(
                                                    field._id,
                                                    field.type,
                                                    values
                                                  );
                                                  setSelectedOptions(values);
                                                }}
                                              >
                                                <VStack
                                                  align="start"
                                                  spacing={2}
                                                >
                                                  {field.options.map(option => (
                                                    <Checkbox
                                                      key={option}
                                                      value={option}
                                                    >
                                                      {option}
                                                    </Checkbox>
                                                  ))}
                                                </VStack>
                                              </CheckboxGroup>
                                            </Box>
                                          )}
                                        </Box>
                                      </FormControl>
                                    )}
                                    {field.type === 'number' && (
                                      <InputGroup size="md">
                                        <Input
                                          name={field.name}
                                          fontSize="sm"
                                          placeholder="Entrer la valeure"
                                          mb="24px"
                                          size="lg"
                                          variant="auth"
                                          type="number"
                                          aria-label="Entrez un nombre"
                                          value={
                                            fieldsValues.find(
                                              item =>
                                                item.field_id === field._id
                                            )?.value
                                          }
                                          onChange={value =>
                                            handleFieldsChange(
                                              field._id,
                                              field.type,
                                              value
                                            )
                                          }
                                        />
                                      </InputGroup>
                                    )}
                                  </FormControl>
                                </Box>

                                {tableSubFields
                                  .filter(
                                    subField =>
                                      subField.customFieldId === field._id
                                  )
                                  .map(subField => (
                                    <Box height="90px" key={subField._id}>
                                      <FormLabel
                                        ms="4px"
                                        fontSize="md"
                                        fontWeight="500"
                                        color={textColor}
                                        display="flex"
                                      >
                                        {subField.name}
                                      </FormLabel>
                                      <FormControl isRequired={true}>
                                        {subField.type === 'text' && (
                                          <InputGroup size="md">
                                            <Input
                                              fontSize="sm"
                                              placeholder="Enter text"
                                              mb="24px"
                                              size="lg"
                                              variant="auth"
                                              value={
                                                subFieldsValues.find(
                                                  item =>
                                                    item.field_id ===
                                                    subField._id
                                                )?.value
                                              }
                                              onChange={value =>
                                                handleFieldsChange(
                                                  subField._id,
                                                  subField.type,
                                                  value
                                                )
                                              }
                                              name={subField.name}
                                            />
                                          </InputGroup>
                                        )}
                                        {subField.type === 'textarea' && (
                                          <Textarea
                                            fontSize="sm"
                                            placeholder="Enter text"
                                            mb="24px"
                                            size="lg"
                                            variant="auth"
                                            name={subField.name}
                                            value={
                                              subFieldsValues.find(
                                                item =>
                                                  item.field_id === subField._id
                                              )?.value
                                            }
                                            onChange={value =>
                                              handleSubFieldsChange(
                                                subField._id,
                                                subField.type,
                                                value
                                              )
                                            }
                                          />
                                        )}
                                        {subField.type === 'radio' && (
                                          <RadioGroup
                                            flexWrap="wrap"
                                            variant="auth"
                                            name={subField.name}
                                            value={
                                              subFieldsValues.find(
                                                item =>
                                                  item.field_id === subField._id
                                              )?.value
                                            }
                                            onChange={value =>
                                              handleSubFieldsChange(
                                                subField._id,
                                                subField.type,
                                                value
                                              )
                                            }
                                            mb="24px"
                                            size="lg"
                                          >
                                            <Wrap
                                              direction="row"
                                              flexWrap="wrap"
                                            >
                                              {field.options.map(option => (
                                                <WrapItem key={option}>
                                                  <Radio value={option}>
                                                    {option}
                                                  </Radio>
                                                </WrapItem>
                                              ))}
                                            </Wrap>
                                          </RadioGroup>
                                        )}
                                        {subField.type === 'select' && (
                                          <Select
                                            placeholder="Select option"
                                            name={subField.name}
                                            value={
                                              subFieldsValues.find(
                                                item =>
                                                  item.field_id === subField._id
                                              )?.value
                                            }
                                            onChange={value =>
                                              handleSubFieldsChange(
                                                subField._id,
                                                subField.type,
                                                value
                                              )
                                            }
                                            fontSize="sm"
                                            mb="24px"
                                            size="lg"
                                            variant="auth"
                                          >
                                            {subField.options.map(option => (
                                              <option
                                                key={option}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            ))}
                                          </Select>
                                        )}
                                        {subField.type === 'multi' && (
                                          <FormControl isRequired={true}>
                                            <Box position="relative">
                                              <InputGroup>
                                                <Input
                                                  placeholder="Select options"
                                                  variant="auth"
                                                  value={selectedOptions.join(
                                                    ', '
                                                  )}
                                                  readOnly
                                                  cursor="pointer"
                                                  onClick={() =>
                                                    setIsDropdownOpen(
                                                      !isDropdownOpen
                                                    )
                                                  }
                                                  pr="3rem" // Add padding-right to make space for the icon
                                                />
                                                <InputRightElement
                                                  pointerEvents="none"
                                                  top="50%" // Vertically align the icon
                                                  right="0.5rem" // Adjust the positioning of the icon
                                                  transform="translateY(-50%)" // Vertically center the icon
                                                  cursor="pointer"
                                                >
                                                  <Icon as={FiChevronDown} />
                                                </InputRightElement>
                                              </InputGroup>

                                              {isDropdownOpen && (
                                                <Box
                                                  position="absolute"
                                                  top="100%"
                                                  left={0}
                                                  zIndex={1}
                                                  width="100%"
                                                  bg={bg}
                                                  p={2}
                                                  borderWidth={1}
                                                  boxShadow="sm"
                                                >
                                                  <CheckboxGroup
                                                    colorScheme="blue"
                                                    value={selectedOptions}
                                                    onChange={values => {
                                                      handleFieldsChange(
                                                        subField._id,
                                                        subField.type,
                                                        values
                                                      );
                                                      setSelectedOptions(
                                                        values
                                                      );
                                                    }}
                                                  >
                                                    <VStack
                                                      align="start"
                                                      spacing={2}
                                                    >
                                                      {subField.options.map(
                                                        option => (
                                                          <Checkbox
                                                            key={option}
                                                            value={option}
                                                          >
                                                            {option}
                                                          </Checkbox>
                                                        )
                                                      )}
                                                    </VStack>
                                                  </CheckboxGroup>
                                                </Box>
                                              )}
                                            </Box>
                                          </FormControl>
                                        )}
                                        {subField.type === 'number' && (
                                          <InputGroup size="md">
                                            <Input
                                              name={field.name}
                                              fontSize="sm"
                                              placeholder="Entrer la valeure"
                                              mb="24px"
                                              size="lg"
                                              variant="auth"
                                              type="number"
                                              aria-label="Entrez un nombre"
                                              value={
                                                fieldsValues.find(
                                                  item =>
                                                    item.field_id === field._id
                                                )?.value
                                              }
                                              onChange={value =>
                                                handleFieldsChange(
                                                  field._id,
                                                  field.type,
                                                  value
                                                )
                                              }
                                            />
                                          </InputGroup>
                                        )}
                                      </FormControl>
                                    </Box>
                                  ))}
                              </>
                            ))}
                          </>
                        )}

                        {selectedCategory && (
                          <>
                            {tableFieldsCat.map(field => (
                              <Box height="90px" m={2}>
                                <FormLabel
                                  ms="4px"
                                  fontSize="md"
                                  fontWeight="500"
                                  color={textColor}
                                  display="flex"
                                >
                                  {field.name}
                                </FormLabel>
                                <FormControl isRequired={true}>
                                  {field.type === 'text' && (
                                    <InputGroup size="md">
                                      <Input
                                        name={field.name}
                                        fontSize="sm"
                                        placeholder="Enter text"
                                        mb="24px"
                                        size="lg"
                                        variant="auth"
                                        value={
                                          fieldsValues.find(
                                            item => item.field_id === field._id
                                          )?.value
                                        }
                                        onChange={value =>
                                          handleFieldsChange(
                                            field._id,
                                            field.type,
                                            value
                                          )
                                        }
                                      />
                                    </InputGroup>
                                  )}
                                  {field.type === 'textarea' && (
                                    <Textarea
                                      fontSize="sm"
                                      placeholder="Enter text"
                                      mb="24px"
                                      size="lg"
                                      variant="auth"
                                      name={field.name}
                                      value={
                                        fieldsValues.find(
                                          item => item.field_id === field._id
                                        )?.value
                                      }
                                      onChange={value =>
                                        handleFieldsChange(
                                          field._id,
                                          field.type,
                                          value
                                        )
                                      }
                                    />
                                  )}
                                  {field.type === 'radio' && (
                                    <RadioGroup
                                      variant="auth"
                                      name={field.name}
                                      value={
                                        fieldsValues.find(
                                          item => item.field_id === field._id
                                        )?.value
                                      }
                                      onChange={value =>
                                        handleFieldsChange(
                                          field._id,
                                          field.type,
                                          value
                                        )
                                      }
                                      mb="24px"
                                      size="lg"
                                    >
                                      <Wrap direction="row" flexWrap="wrap">
                                        {field.options.map(option => (
                                          <WrapItem key={option}>
                                            <Radio value={option}>
                                              {option}
                                            </Radio>
                                          </WrapItem>
                                        ))}
                                      </Wrap>
                                    </RadioGroup>
                                  )}

                                  {field.type === 'select' && (
                                    <Select
                                      placeholder="Select option"
                                      name={field.name}
                                      value={
                                        fieldsValues.find(
                                          item => item.field_id === field._id
                                        )?.value
                                      }
                                      onChange={value =>
                                        handleFieldsChange(
                                          field._id,
                                          field.type,
                                          value
                                        )
                                      }
                                      fontSize="sm"
                                      mb="24px"
                                      size="lg"
                                      variant="auth"
                                    >
                                      {field.options.map(option => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </Select>
                                  )}
                                  {field.type === 'multi' && (
                                    <FormControl isRequired={true}>
                                      <Box position="relative">
                                        <InputGroup>
                                          <Input
                                            placeholder="Select options"
                                            variant="auth"
                                            value={selectedOptions.join(', ')}
                                            readOnly
                                            cursor="pointer"
                                            onClick={() =>
                                              setIsDropdownOpen(!isDropdownOpen)
                                            }
                                            pr="3rem" // Add padding-right to make space for the icon
                                          />
                                          <InputRightElement
                                            pointerEvents="none"
                                            top="50%" // Vertically align the icon
                                            right="0.5rem" // Adjust the positioning of the icon
                                            transform="translateY(-50%)" // Vertically center the icon
                                            cursor="pointer"
                                          >
                                            <Icon as={FiChevronDown} />
                                          </InputRightElement>
                                        </InputGroup>

                                        {isDropdownOpen && (
                                          <Box
                                            position="absolute"
                                            top="100%"
                                            left={0}
                                            zIndex={1}
                                            width="100%"
                                            bg={bg}
                                            p={2}
                                            borderWidth={1}
                                            boxShadow="sm"
                                          >
                                            <CheckboxGroup
                                              colorScheme="blue"
                                              value={selectedOptions}
                                              onChange={values => {
                                                handleFieldsChange(
                                                  field._id,
                                                  field.type,
                                                  values
                                                );
                                                setSelectedOptions(values);
                                              }}
                                            >
                                              <VStack align="start" spacing={2}>
                                                {field.options.map(option => (
                                                  <Checkbox
                                                    key={option}
                                                    value={option}
                                                  >
                                                    {option}
                                                  </Checkbox>
                                                ))}
                                              </VStack>
                                            </CheckboxGroup>
                                          </Box>
                                        )}
                                      </Box>
                                    </FormControl>
                                  )}
                                  {field.type === 'number' && (
                                    <InputGroup size="md">
                                      <Input
                                        name={field.name}
                                        fontSize="sm"
                                        placeholder="Entrer la valeure"
                                        mb="24px"
                                        size="lg"
                                        variant="auth"
                                        type="number"
                                        aria-label="Entrez un nombre"
                                        value={
                                          fieldsValues.find(
                                            item => item.field_id === field._id
                                          )?.value
                                        }
                                        onChange={value =>
                                          handleFieldsChange(
                                            field._id,
                                            field.type,
                                            value
                                          )
                                        }
                                      />
                                    </InputGroup>
                                  )}
                                </FormControl>
                              </Box>
                            ))}
                          </>
                        )}
                      </SimpleGrid>
                    </FormControl>
                    <ButtonGroup mt="5%" w="100%">
                      <Flex w="100%" justifyContent="space-between">
                        <Flex>
                          <Button
                            onClick={() => {
                              setStep(step - 1);
                              setProgress(progress - 33.33);
                            }}
                            isDisabled={step === 1}
                            colorScheme="purple"
                            variant="solid"
                            w="7rem"
                            mr="5%"
                          >
                            {t('Précédent')}
                          </Button>
                          <Button
                            w="7rem"
                            isDisabled={user.phone === '060000000' ||
                            user.phone === '+21260000000' ||
                            user.isPhoneVerified === false ||
                            !user.hasOwnProperty('isPhoneVerified') }
                            onClick={() => {
                              if (!selectedCategory.trim()) {
                                toast.error(
                                  'Attribuez une catégorie à votre annonce!',
                                  {
                                    position: 'bottom-center',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: 'colored',
                                  }
                                );
                                if (!ad.type.trim()) {
                                  toast.error(
                                    'Entrez le type de votre annonce!',
                                    {
                                      position: 'bottom-center',
                                      autoClose: 5000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                      theme: 'colored',
                                    }
                                  );
                                  return;
                                }
                              } else {
                                setStep(step + 1);
                                if (step === 3) {
                                  setStep(3);
                                } else {
                                  setStep(step + 1);
                                  setProgress(progress + 33.33);
                                }
                              }
                            }}
                            colorScheme="purple"
                            variant="outline"
                          >
                            {t('Suivant')}
                          </Button>
                        </Flex>
                      </Flex>
                    </ButtonGroup>
                  </>
                ) : step === 2 ? (
                  <>
                    <Heading
                      w="100%"
                      textAlign={'center'}
                      fontWeight="normal"
                      mb="2%"
                    >
                      {t('Etape 2: Identification de votre annonce')}
                      <Tooltip
                        label="Ajoutez des photos, donnez un nom et une description à votre annonce, puis définissez le prix pour compléter cette étape."
                        fontSize="md"
                      >
                        <InfoIcon color="brand.400" ml={2} pb={1} />
                      </Tooltip>
                    </Heading>
                    <FormControl>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="md"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                        mt={10}
                      >
                        {t('Ajoutez les images du produit (Max 6 images)')}
                      </FormLabel>{' '}
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                        mt={10}
                      >
                        <ul>
                          <li>
                            {t(
                              'Une annonce avec des images a plus de visibilité'
                            )}{' '}
                          </li>
                          <li>{t('La première image est la principale')} </li>
                          <li>
                            {t(
                              `Pour changer l'image principale glisser l'image de votre choix vers la première case`
                            )}
                          </li>
                        </ul>
                      </FormLabel>{' '}
                      <Box>
                        <Grid
                          gridTemplateColumns={{
                            xl: 'repeat(3, 1fr)',
                            '2xl': '1fr 0.46fr',
                          }}
                          display={{ base: 'block', xl: 'grid' }}
                          gap={4}
                          mb="10px"
                        >
                          {files.map((file, index) => {
                            return (
                              <GridItem key={index} mb={2}>
                                <Box
                                  border={
                                    index === 0
                                      ? '4px dashed purple'
                                      : '2px dashed gray'
                                  }
                                  height="100%"
                                  width="100%"
                                  onDragOver={handleDragOver}
                                  onDrop={event => handleDrop(event, index)}
                                  borderRadius={'20px'}
                                >
                                  {file ? (
                                    <>
                                      <Box position="relative">
                                        <IconButton
                                          colorScheme="red"
                                          aria-label="Delete Image"
                                          icon={<CloseIcon />}
                                          size="sm"
                                          onClick={() => handleDelete(index)}
                                          position="absolute"
                                          top="2"
                                          right="2"
                                        />
                                        <Image
                                          borderRadius={'20px'}
                                          src={URL.createObjectURL(file)}
                                          w="100%"
                                          h="100%"
                                          objectFit="cover"
                                          draggable
                                          onDragStart={event =>
                                            handleDragStart(event, index)
                                          }
                                        />
                                      </Box>
                                    </>
                                  ) : index === 0 ? (
                                    <Button
                                      as="label"
                                      htmlFor="imageInput"
                                      w="100px"
                                      h="100px"
                                      bg="transparent"
                                      boxShadow="none"
                                      _hover={{ bg: 'transparent' }}
                                      _active={{ bg: 'transparent' }}
                                      _focus={{ boxShadow: 'none' }}
                                      leftIcon={<AddIcon />}
                                      cursor="pointer"
                                    >
                                      {t(`Image principale`)}
                                      <Input
                                        id="imageInput"
                                        type="file"
                                        accept="image/*"
                                        style={{
                                          display: 'none',
                                        }}
                                        onChange={event =>
                                          handleFileChange(event, index)
                                        }
                                      />
                                    </Button>
                                  ) : (
                                    <Button
                                      as="label"
                                      htmlFor="imageInput"
                                      w="100px"
                                      h="100px"
                                      bg="transparent"
                                      _hover={{ bg: 'transparent' }}
                                      _active={{ bg: 'transparent' }}
                                      _focus={{ boxShadow: 'none' }}
                                      leftIcon={<AddIcon />}
                                      cursor="pointer"
                                      boxShadow="none"
                                    >
                                      {t('Ajouter une image')}
                                      <Input
                                        id="imageInput"
                                        type="file"
                                        accept="image/*"
                                        style={{
                                          display: 'none',
                                        }}
                                        onChange={event =>
                                          handleFileChange(event, index)
                                        }
                                      />
                                    </Button>
                                  )}
                                </Box>
                              </GridItem>
                            );
                          })}
                        </Grid>
                      </Box>
                      <Flex h="100%" px={5} mb={2}></Flex>
                      <Box height="90px">
                        <FormLabel
                          display="flex"
                          ms="4px"
                          fontSize="md"
                          fontWeight="500"
                          color={textColor}
                          mb="8px"
                        >
                          {t(`Nom de l 'annonce`)}
                          <Text color={brandStars}>*</Text>{' '}
                        </FormLabel>{' '}
                        <Input
                          id="name"
                          isRequired={true}
                          variant="auth"
                          name="name"
                          fontSize="sm"
                          ms={{ base: '0px', md: '0px' }}
                          type="text"
                          placeholder={t(`Entrez le nom de l'annonce`)}
                          mb="24px"
                          fontWeight="500"
                          size="lg"
                          value={ad.name}
                          onChange={HandleChange}
                        />
                      </Box>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="md"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                      >
                        {t(`Entrez la description de l 'annonce`)}
                        <Text color={brandStars}>*</Text>{' '}
                      </FormLabel>
                      <Textarea
                        id="description"
                        fontSize="sm"
                        mb="5px"
                        fontWeight="500"
                        size="lg"
                        ms={{ base: '0px', md: '0px' }}
                        isRequired={true}
                        placeholder="description de l'annonce"
                        value={ad.description}
                        name="description"
                        onChange={HandleChange}
                      />
                      <Text
                        align="right"
                        fontSize="sm"
                        color={
                          ad.description.length > 500 ||
                          ad.description.length < 10
                            ? 'red'
                            : textColor
                        }
                      >
                        {ad.description.length}/500
                      </Text>
                      <Box height="90px">
                        <FormLabel
                          ms="4px"
                          fontSize="md"
                          fontWeight="500"
                          color={textColor}
                          display="flex"
                        >
                          {t(`Entrez le prix en MAD`)}{' '}
                          <Text color={brandStars}> * </Text>{' '}
                        </FormLabel>{' '}
                        <InputGroup size="md">
                          <Input
                            isRequired={true}
                            fontSize="sm"
                            placeholder={t(`Entrez le prix en MAD`)}
                            mb="24px"
                            size="lg"
                            variant="auth"
                            value={ad.price}
                            name="price"
                            onChange={HandleChange}
                          />
                        </InputGroup>{' '}
                      </Box>
                      {user?.ProPart === 'pro' && (
                        <Box>
                          <Flex justify="center">
                            <Text
                              textAlign={'center'}
                              fontWeight="bold"
                              fontSize="xl"
                              mb="2%"
                              mt="2%"
                            >
                              {t('Section Réservée aux Boutiques JINDOO')}
                            </Text>
                            <Box pt="2" ml={3}>
                              <MdWorkspacePremium color={premium} size={40} />
                            </Box>
                          </Flex>

                          <Flex w="100%" justify={'space-around'}>
                            <Box
                              border="2px dashed gray"
                              height="300px"
                              width="300px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              position="relative"
                              borderRadius={'20px'}
                              mb="10px"
                            >
                              {!video && (
                                <Button
                                  variant="ghost"
                                  as="label"
                                  htmlFor="videoInput"
                                  w="150px"
                                  h="150px"
                                  bg={bgUpload}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                  justifyContent="center"
                                  _hover={{ bg: 'transparent' }}
                                  _active={{ bg: 'transparent' }}
                                  _focus={{ boxShadow: 'none' }}
                                  cursor="pointer"
                                  boxShadow="none"
                                >
                                  <AiOutlineVideoCameraAdd size={50} />
                                  <Text mt={2}>{t('Ajouter une vidéo')}</Text>
                                  <Text fontWeight={'200'} fontSize={'14px'} mt={2}>
                                      {t('Taille Max : 60MB')}
                                    </Text>
                                  <Text fontWeight={'200'} fontSize={'14px'} >
                                      {t('Durée Max : 1 Minute')}
                                    </Text>
                                  <Input
                                    id="videoInput"
                                    type="file"
                                    accept="video/*"
                                    style={{ display: 'none' }}
                                    onChange={handleVideoChange} // Add your handle function here
                                  />
                                </Button>
                              )}

                              {video && (
                                <Box>
                                  <Box
                                    position="absolute"
                                    top="2"
                                    right="2"
                                    zIndex="999"
                                    borderRadius={'20px'}
                                  >
                                    <IconButton
                                      colorScheme="red"
                                      aria-label="Delete Video"
                                      icon={<CloseIcon />}
                                      size="sm"
                                      onClick={() => setVideo(null)}
                                    />
                                  </Box>

                                  <Box
                                    borderRadius={'20px'}
                                    as="video"
                                    controls
                                    height="300px"
                                    width="300px"
                                    maxH={'300px'}
                                    maxW={'300px'}
                                    src={URL.createObjectURL(video)}
                                    alt={video.name}
                                    objectFit="cover"
                                  />
                                </Box>
                              )}
                            </Box>

                            <Box
                              border="2px dashed gray"
                              height="300px"
                              width="300px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              position="relative"
                              borderRadius={'20px'}
                            >
                              {!proFile && (
                                <Button
                                  as="label"
                                  htmlFor="fileInput"
                                  w="280px"
                                  h="150px"
                                  bg={bgUpload}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                  justifyContent="center"
                                  _hover={{ bg: 'transparent' }}
                                  _active={{ bg: 'transparent' }}
                                  _focus={{ boxShadow: 'none' }}
                                  cursor="pointer"
                                  boxShadow="none"
                                >
                                  <AiOutlineFileAdd size={50} />
                                  <Text mt={2}>
                                    {t('Ajouter un fichier (PDF ou Word)')}
                                  </Text>
                                  <Text fontWeight={'200'} fontSize={'14px'} mt={2}>
                                      {t('Taille Max : 2MB')}
                                    </Text>
                                  <Input
                                    id="fileInput"
                                    type="file"
                                    accept=".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    style={{ display: 'none' }}
                                    onChange={handleProFileChange} // Add your handle function here
                                  />
                                </Button>
                              )}

                              {proFile && (
                                <FilePreview
                                  file={proFile}
                                  onDelete={() => setProFile(null)}
                                />
                              )}
                            </Box>
                          </Flex>
                        </Box>
                      )}
                    </FormControl>
                    <ButtonGroup mt="5%" w="100%">
                      <Flex w="100%" justifyContent="space-between">
                        <Flex>
                          <Button
                            onClick={() => {
                              setStep(step - 1);
                              setProgress(progress - 33.33);
                            }}
                            isDisabled={step === 1}
                            colorScheme="purple"
                            variant="solid"
                            w="7rem"
                            mr="5%"
                          >
                            {t('Précédent')}
                          </Button>
                          <Button
                            w="7rem"
                            isDisabled={step === 3}
                            onClick={() => {
                              if (!ad.name.trim()) {
                                toast.error('Nommez votre annonce!', {
                                  position: 'bottom-center',
                                  autoClose: 5000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: 'colored',
                                });
                                return;
                              }
                              if (!ad.description.trim()) {
                                toast.error('Décrivez votre annonce!', {
                                  position: 'bottom-center',
                                  autoClose: 5000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: 'colored',
                                });
                                return;
                              }
                              if (ad.name.length > 50) {
                                toast.error(
                                  'Le nom ne doit pas dépasser 50 caractères!',
                                  {
                                    position: 'bottom-center',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: 'colored',
                                  }
                                );
                                return;
                              }
                              if (ad.description.length < 10) {
                                toast.error(
                                  'La description doit comporter au moins 10 caractères!',
                                  {
                                    position: 'bottom-center',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: 'colored',
                                  }
                                );
                                return;
                              } else {
                                setStep(step + 1);
                                if (step === 3) {
                                  setStep(3);
                                } else {
                                  setProgress(progress + 33.33);
                                  setStep(step + 1);
                                }
                              }
                            }}
                            colorScheme="purple"
                            variant="outline"
                          >
                            {t('Suivant')}
                          </Button>
                        </Flex>
                      </Flex>
                    </ButtonGroup>
                  </>
                ) : step === 3 ? (
                  <>
                    <Heading
                      w="100%"
                      textAlign={'center'}
                      fontWeight="normal"
                      mb="2%"
                    >
                      {t('Etape 3: Localisation de votre annonce ')}{' '}
                      <Tooltip
                        label="Indiquez la ville et le quartier où se situe l'annonce pour que les utilisateurs puissent localiser votre offre facilement."
                        fontSize="md"
                      >
                        <InfoIcon color="brand.400" ml={2} pb={1} />
                      </Tooltip>
                    </Heading>
                    <FormControl>
                      <Box height="90px">
                        <FormLabel
                          ms="4px"
                          fontSize="md"
                          fontWeight="500"
                          color={textColor}
                          display="flex"
                        >
                          {t(`Choisissez votre ville`)}{' '}
                          <Text color={brandStars}> * </Text>{' '}
                        </FormLabel>{' '}
                        <InputGroup>
                          <Select
                            id="city"
                            name="location"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            placeholder={t(`Choisissez votre ville`)}
                            mb="24px"
                            fontWeight="200"
                            size="lg"
                            onChange={e => {
                              const selectedCityName = e.target.value;

                              const selectedCity = cities.find(
                                city => city.name === selectedCityName
                              );

                              if (selectedCity) {
                                setSelectedCityId(selectedCity.id);
                                // do something with the selected city id
                              }
                              setSelectedCity(e.target.value);
                            }}
                          >
                            {' '}
                            {cities.map(city => (
                              <option key={city.id} value={city.name}>
                                {' '}
                                {city.name}{' '}
                              </option>
                            ))}{' '}
                          </Select>
                        </InputGroup>
                      </Box>{' '}
                      <Box height="90px">
                        <FormLabel
                          ms="4px"
                          fontSize="md"
                          fontWeight="500"
                          color={textColor}
                          display="flex"
                        >
                          {t(`Choisissez votre secteur`)}{' '}
                          <Text color={brandStars}> * </Text>{' '}
                        </FormLabel>{' '}
                        <InputGroup>
                          <Select
                            id="city"
                            name="location"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            placeholder={t(`Choisissez votre secteur`)}
                            mb="24px"
                            fontWeight="200"
                            size="lg"
                            onChange={e => setSelectedsecteur(e.target.value)}
                          >
                            {' '}
                            {secteurs.map(secteur => (
                              <option key={secteur._id} value={secteur.name}>
                                {' '}
                                {secteur.name}{' '}
                              </option>
                            ))}{' '}
                          </Select>
                        </InputGroup>
                      </Box>
                      <Alert status="error" mb={5} align="start">
                        <AlertDescription>
                          {t(
                            `Pour créer une nouvelle annonce, veuillez noter que les contenus à caractère sexuel, promotionnels de produits illégaux ou dangereux, ou tout autre contenu considéré comme inapproprié ne seront pas acceptés. Nous nous réservons le droit de refuser ou de retirer toute annonce qui ne respecte pas nos règles de publication. Merci de votre compréhension.`
                          )}
                        </AlertDescription>
                      </Alert>
                    </FormControl>
                    <ButtonGroup mt="5%" w="100%" alignContent="flex-end">
                      <Flex w="100%" justifyContent="space-between">
                        {step === 3 ? (
                          <Button
                            onClick={() => {
                              setStep(step - 1);
                              setProgress(progress - 33.33);
                            }}
                            isDisabled={step === 1}
                            colorScheme="purple"
                            variant="solid"
                            w="7rem"
                            mr="5%"
                          >
                            {t('Précédent')}
                          </Button>
                        ) : null}
                        {step === 3 ? (
                          <Button
                            fontSize="sm"
                            variant="brand"
                            fontWeight="500"
                            w="7rem"
                            onClick={handleSubmit}
                          >
                            {t(`Ajouter`)}{' '}
                          </Button>
                        ) : null}
                      </Flex>
                    </ButtonGroup>
                  </>
                ) : (
                  <>
                    <Heading
                      w="100%"
                      textAlign={'center'}
                      fontWeight="normal"
                      mb="2%"
                    >
                      {t('Annonce envoyée pour validation ')}
                      <Tooltip
                        label="Votre annonce est en cours de validation. Cela peut prendre jusqu'à 24 heures."
                        fontSize="md"
                      >
                        <InfoIcon color="orange.400" ml={2} pb={1} />
                      </Tooltip>
                    </Heading>
                    <Box mt="2" mb={2}>
                      <Box alignItems="center" mb="2">
                        <Alert status="success" mb={5} textAlign="start">
                          <AlertDescription fontStyle="oblique">
                            Nous avons bien reçu votre annonce et nous vous
                            remercions de votre confiance. Votre annonce est en
                            cours de vérification et sera publiée prochainement
                            si elle respecte nos critères de publication. Nous
                            vous informerons de la décision prise dans les
                            prochaines heures. Merci de patienter pendant que
                            nous examinons votre annonce.
                          </AlertDescription>
                        </Alert>
                      </Box>

                      {/* Estimated Time */}
                      <Text fontSize="sm" color="gray.500">
                        Estimation du temps de validation : 24 heures
                      </Text>
                      {subscriptions.length > 0 && (
                        <>
                          <Text
                            textAlign="start"
                            color={textColorPrimary}
                            fontWeight="bold"
                            fontSize="2xl"
                            mt="10px"
                            mb="15px"
                          >
                            {t('Vous voulez plus de visiteurs ?')}
                          </Text>
                          <Grid
                            templateColumns={{ base: '1fr', md: '1fr 1fr 1fr' }}
                            display={{ base: 'block', md: 'grid' }}
                            gap={6}
                            mb={8}
                          >
                            {Array.isArray(subscriptions) &&
                              subscriptions.map((subscription, index) => {
                                let priceForSelectedDays = [];
                                const selectedPromoDays =
                                  subscription.days[dayIndices[index]];
                                const selectedRepostsDays =
                                  subscription.reposts[repostIndices[index]];
                                if (selectedPromoDays) {
                                  priceForSelectedDays =
                                    subscription.price[priceIndices[index]][
                                      selectedPromoDays.toString()
                                    ];
                                } else if (selectedRepostsDays) {
                                  priceForSelectedDays =
                                    subscription.price[priceIndices[index]][
                                      selectedRepostsDays.toString()
                                    ];
                                }
                                const handleSelection = () => {
                                  makeOrder(
                                    subscription._id,
                                    ad._id,
                                    subscription.days[dayIndices[index]],
                                    subscription.reposts[repostIndices[index]],
                                    priceForSelectedDays
                                  ); // Get the ad by its id when the component is clicked
                                };

                                return (
                                  <>
                                    {subscription.name !== 'SILVER' && (
                                      <Card
                                        mb={5}
                                        mt={5}
                                        bg={bgColor}
                                        borderWidth="1px"
                                        position="relative"
                                        p="2"
                                        py="30px"
                                      >
                                        <Box textAlign="center">
                                          <Box
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Text color="purple.600">
                                              {subscription.name}
                                            </Text>
                                            <Text
                                              pt={2}
                                              fontSize="xl"
                                              fontWeight="700"
                                              color="purple.600"
                                            >
                                              {priceForSelectedDays} MAD/Jour
                                            </Text>
                                          </Box>

                                          <Flex justify="center" mb={10}>
                                            <Flex>
                                              <Text
                                                mr={1}
                                                fontSize="sm"
                                                color="gray.600"
                                              >
                                                {t(`Recevez jusqu'à`)}
                                              </Text>
                                              <Text
                                                fontSize="sm"
                                                fontWeight="700"
                                                color="purple.600"
                                              >
                                                {subscription.name === 'GOLD'
                                                  ? t(`25x plus de visibilité`)
                                                  : t(`15x plus de visibilité`)}
                                              </Text>
                                            </Flex>
                                          </Flex>

                                          <Divider
                                            variant="dashed"
                                            w={'60%'}
                                            mx={'auto'}
                                            colorScheme={packColor}
                                            borderWidth={1}
                                            borderColor={packColor}
                                            mb={2}
                                          />

                                          <Flex justify="center" mb={2}>
                                            <Box>
                                              <Flex mb={2}>
                                                {subscription.days[0] === 0 ? (
                                                  <Icon
                                                    as={MdOutlineClear}
                                                    boxSize={5}
                                                    color="red"
                                                    mr={1}
                                                  />
                                                ) : (
                                                  <Icon
                                                    as={MdCheck}
                                                    boxSize={5}
                                                    color="green"
                                                    mr={1}
                                                  />
                                                )}
                                                <Text
                                                  fontSize="sm"
                                                  color="gray.600"
                                                >
                                                  {subscription.days[0] ===
                                                  0 ? (
                                                    'Pas de promotion'
                                                  ) : (
                                                    <>
                                                      <span>Promouvoir </span>
                                                      <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        fontSize="lg"
                                                        color="purple.600"
                                                      >
                                                        {
                                                          subscription.days[
                                                            dayIndices[index]
                                                          ]
                                                        }{' '}
                                                        jours
                                                      </Text>
                                                    </>
                                                  )}
                                                </Text>
                                              </Flex>
                                              <Flex>
                                                {subscription.reposts[0] ===
                                                0 ? (
                                                  <Icon
                                                    as={MdOutlineClear}
                                                    boxSize={5}
                                                    color="red"
                                                    mr={1}
                                                  />
                                                ) : (
                                                  <Icon
                                                    as={MdCheck}
                                                    boxSize={5}
                                                    color="green"
                                                    mr={1}
                                                  />
                                                )}
                                                <Text
                                                  fontSize="sm"
                                                  color="gray.600"
                                                >
                                                  {subscription.reposts[0] ===
                                                  0 ? (
                                                    'Pas de promotion'
                                                  ) : (
                                                    <>
                                                      <span>
                                                        Renouvelement{' '}
                                                      </span>
                                                      <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        fontSize="lg"
                                                        color="purple.600"
                                                      >
                                                        {
                                                          subscription.reposts[
                                                            repostIndices[index]
                                                          ]
                                                        }{' '}
                                                        jours
                                                      </Text>
                                                    </>
                                                  )}
                                                </Text>
                                              </Flex>
                                            </Box>
                                          </Flex>

                                          <Divider
                                            variant="dashed"
                                            textAlign="center"
                                            w={'60%'}
                                            mx={'auto'}
                                            colorScheme={packColor}
                                            borderWidth={1}
                                            borderColor={packColor}
                                            mb={2}
                                          />

                                          {/* Left Side Border with Decrement Icon */}
                                          <Box
                                            position="absolute"
                                            top="0"
                                            bottom="0"
                                            left="0"
                                            width="30px"
                                            bg="purple.500"
                                            color="white"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            borderTopLeftRadius="lg"
                                            borderBottomLeftRadius="lg"
                                            onClick={() =>
                                              handleSubtract(index)
                                            }
                                            cursor="pointer"
                                          >
                                            <HiOutlineMinusCircle size={24} />
                                          </Box>

                                          {/* Right Side Border with Increment Icon */}
                                          <Box
                                            position="absolute"
                                            top="0"
                                            bottom="0"
                                            right="0"
                                            width="30px"
                                            bg="purple.500"
                                            color="white"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            borderTopRightRadius="lg"
                                            borderBottomRightRadius="lg"
                                            onClick={() => handleAdd(index)}
                                            cursor="pointer"
                                          >
                                            <HiOutlinePlusCircle size={24} />
                                          </Box>

                                          <Flex justify="center" pt={2}>
                                            <Button
                                              onClick={handleSelection}
                                              variant="lightBrand"
                                              color="purple.600"
                                            >
                                              Selectionner
                                            </Button>
                                          </Flex>
                                        </Box>
                                      </Card>
                                    )}
                                    {subscription.name === 'SILVER' && (
                                      <Card
                                        shadow="xl"
                                        position="relative"
                                        bg={bgColor}
                                        borderWidth="1px"
                                        py="30px"
                                      >
                                        <Box textAlign="center">
                                          <Box
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Text
                                              color={packColor}
                                              fontSize={20}
                                            >
                                              {subscription.name}
                                            </Text>
                                            <Text
                                              pt={2}
                                              fontSize="2xl"
                                              fontWeight="700"
                                              color={packColor}
                                            >
                                              {`${priceForSelectedDays} MAD/Jour`}{' '}
                                            </Text>
                                          </Box>
                                          <Flex justify="center" mb={10}>
                                            <Flex>
                                              <Text
                                                mr={1}
                                                fontSize="sm"
                                                color="gray.600"
                                              >
                                                {t(`Recevez jusqu'à`)}
                                              </Text>
                                              <Text
                                                fontSize="sm"
                                                fontWeight="700"
                                                color={packColor}
                                              >
                                                {t(`20x plus de visibilité`)}
                                              </Text>
                                            </Flex>
                                          </Flex>

                                          <Divider
                                            variant="dashed"
                                            textAlign="center"
                                            colorScheme={packColor}
                                            borderWidth={1}
                                            borderColor={packColor}
                                            w={'60%'}
                                            mx={'auto'}
                                            mb={1}
                                          />
                                          <Flex justify="center" mb={2}>
                                            <Box mb={1}>
                                              <Flex>
                                                <Icon
                                                  as={MdCheck}
                                                  boxSize={5}
                                                  color="green"
                                                  mr={1}
                                                />

                                                <Text
                                                  mr={1}
                                                  fontSize="sm"
                                                  color="gray.600"
                                                >
                                                  {subscription.days[0] ===
                                                  0 ? (
                                                    'Pas de promotion'
                                                  ) : (
                                                    <>
                                                      <span>Promouvoir </span>
                                                      <Text
                                                        as="span"
                                                        fontWeight="bold"
                                                        fontSize="lg" // Adjust the size as needed
                                                        color={packColor} // Set your desired color
                                                      >
                                                        {
                                                          subscription.days[
                                                            dayIndices[index]
                                                          ]
                                                        }{' '}
                                                        jours
                                                      </Text>
                                                    </>
                                                  )}
                                                </Text>
                                              </Flex>

                                              <Flex>
                                                {subscription.reposts[0] ===
                                                0 ? (
                                                  <Icon
                                                    as={MdOutlineClear}
                                                    boxSize={5}
                                                    color="red"
                                                    mr={1}
                                                  />
                                                ) : (
                                                  <Icon
                                                    as={MdCheck}
                                                    boxSize={5}
                                                    color="green"
                                                    mr={1}
                                                  />
                                                )}

                                                <Text
                                                  mr={1}
                                                  fontSize="sm"
                                                  color="gray.600"
                                                >
                                                  {subscription.reposts[0] === 0
                                                    ? 'Pas de renouvelement'
                                                    : `Renouvelement ${
                                                        subscription.reposts[
                                                          repostIndices[index]
                                                        ]
                                                      } jours`}
                                                </Text>
                                              </Flex>
                                            </Box>
                                          </Flex>
                                          <Divider
                                            variant="dashed"
                                            textAlign="center"
                                            colorScheme={packColor}
                                            borderWidth={1}
                                            borderColor={packColor}
                                            w={'60%'}
                                            mx={'auto'}
                                            mb={1}
                                          />
                                          <Box
                                            position="absolute"
                                            top="0"
                                            bottom="0"
                                            left="0"
                                            width="30px"
                                            bg={bgBar}
                                            color="white"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            borderTopLeftRadius="lg"
                                            borderBottomLeftRadius="lg"
                                            onClick={() =>
                                              handleSubtract(index)
                                            }
                                            cursor="pointer"
                                          >
                                            <HiOutlineMinusCircle size={24} />
                                          </Box>

                                          {/* Right Side Border with Increment Icon */}
                                          <Box
                                            position="absolute"
                                            top="0"
                                            bottom="0"
                                            right="0"
                                            width="30px"
                                            bg={bgBar}
                                            color="white"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            borderTopRightRadius="lg"
                                            borderBottomRightRadius="lg"
                                            onClick={() => handleAdd(index)}
                                            cursor="pointer"
                                          >
                                            <HiOutlinePlusCircle size={24} />
                                          </Box>
                                          <Flex
                                            w="100%"
                                            justify="center"
                                            pt={2}
                                          >
                                            <Button
                                              onClick={handleSelection}
                                              variant="darkBrand"
                                            >
                                              Selectionner
                                            </Button>
                                          </Flex>
                                        </Box>
                                        <Box
                                          position="absolute"
                                          top="-3"
                                          right="1"
                                          bg="yellow"
                                          color="black"
                                          px={2}
                                          py={1}
                                          borderRadius="md"
                                          fontWeight="bold"
                                        >
                                          Best Seller
                                        </Box>
                                      </Card>
                                    )}
                                  </>
                                );
                              })}
                          </Grid>
                        </>
                      )}
                    </Box>
                    {/* <Flex>
                      <Button
                        w="100%"
                        leftIcon={<MdHomeFilled />}
                        onClick={() => {
                          history.push('/');
                        }}
                        colorScheme="purple"
                        variant="solid"
                      >
                        {t('Accueil')}
                      </Button>
                      <Button
                        ml={1}
                        w="100%"
                        leftIcon={<MdCameraEnhance />}
                        onClick={() => {
                          history.push('/ad/createAd');
                          history.go(0);
                        }}
                        colorScheme="purple"
                        variant="solid"
                      >
                        {t('Annonce')}
                      </Button>
                    </Flex> */}
                  </>
                )}
              </Box>
            </FormControl>
          </>
        )}
      </Flex>
    </Card>
  );
};

export default CreateAd;
