import React from "react";
// Chakra imports
import { Flex, Icon, Image, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import { FaEthereum } from "react-icons/fa";
import { IoMdPricetags } from "react-icons/io";
import Nft3 from 'assets/img/nfts/Nft3.png';


export default function NFT(props) {
  const { image, name, author, date, price,click } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue("brands.900", "white");
  const card = useColorModeValue("rgb(204, 204, 204)", "brands.900");
  const bgItem = useColorModeValue(
    { bg: "white", boxShadow: "unset" },
    { bg: "navy.700", boxShadow: "unset" }
  );
  const textColorDate = useColorModeValue("secondaryGray.600", "white");
  return (
    <Card

      cursor="pointer" // Change cursor to pointer on hover
      boxShadow= "0px 40px 58px -20px rgba(112, 144, 176, 0.12)"
      _hover={bgItem}
   
      
      py='21px'
      transition='0.2s linear'
      onClick={click}
      m="10px"
      >
      
      <Flex direction={{ base: "column" }} justify='center'>
        <Flex position='relative' align='center'>
          <Image src={image} w='66px' h='66px' borderRadius='20px' me='16px'             fallbackSrc={Nft3}
 />
          <Flex
            direction='column'
            w={{ base: "70%", md: "100%" }}
            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}>
            <Text
              color={textColor}
              fontSize={{
                base: "md",
              }}
              mb='5px'
              fontWeight='bold'
              me='14px'>
              {name}
            </Text>
            <Text
              color='secondaryGray.600'
              fontSize={{
                base: "sm",
              }}
              fontWeight='400'
              me='14px'>
              {author}
            </Text>
          </Flex>

    
        </Flex>
      </Flex>
    </Card>
  );
}
