// chakra imports
import {
  Icon,
  Flex,
  Text,
  useColorModeValue,
  MenuItem,
  Box,
  Center,
  Avatar,
  Button
} from '@chakra-ui/react';
import {
  MdUpgrade,
  MdPersonAddAlt1,
  MdOutlineNotificationsPaused,
  MdDeleteForever,
} from 'react-icons/md';
import socket from '../../socket'
import { TiDelete } from 'react-icons/ti';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import { NavLink, useHistory } from 'react-router-dom';
import { useSocket } from '../../contexts/SocketContext'
import { v4 } from "uuid";

export function NewMessage(props) {
  const { Click, currentUserId, conversation } = props;
  const unread = useColorModeValue('gray.200', 'whiteAlpha.200');
  const read = useColorModeValue('navy.700', 'white');
  const [notificationsSocket, setNotificationsSocket] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [notifUsers, setNotifUsers] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const history = useHistory();
  const notificationColor = useColorModeValue('red.600', 'red.200');
  const [conversationsWithLastMessage, setConversationsWithLastMessage] = useState([]);
  const [sendersInfo, setSendersInfo] = useState({});
  const bgItem = useColorModeValue(
    { bg: "white", boxShadow: "unset" },
    { bg: "navy.700", boxShadow: "unset" }
  );
  function generateSessionToken() {
    // Generate a random session token using UUID (Universally Unique Identifier)
    return v4();
  }
  const sessionT = generateSessionToken();
  console.log("sessionT",sessionT);
  const sessionToken = sessionStorage.getItem('sessionToken');

  useEffect(() => {
    if (socket) {
      if (sessionToken) {
        socket.emit('addUser', { userId: currentUserId, sessionToken });
      }
      else 
   {   socket.emit('addUser', { userId: currentUserId, sessionToken: sessionT });
      sessionStorage.setItem('sessionToken', sessionT);}
      socket.on('getUsers', users => {
        setNotifUsers(users);
      });
    }
  }, [currentUserId]);
  console.log("notifUsers",notifUsers);

  useEffect(() => {
    // Check if socket is defined before setting up event listeners
    socket.on('receiveNotification', messageData => {
        // Handle new notifications here
         // Access the data in messageData
         console.log('Received new notification:', messageData);

  const { type, recipient, sender, message } = messageData;

  // Create a new notification object
  const newNotification = {
    type,
    recipient,
    sender,
    message,
    createdAt: Date.now(), // You can set the timestamp here
  };
  if (newNotification.type === 'new_message') {
    setNotificationsSocket(prevNotifications => [
      ...prevNotifications,
      newNotification,
    ]);
  }

  // Update the state with the new notification

        
    });
  }, []);
  console.log("notificationsSocket", notificationsSocket);
 

  const fetchNotifications = async () => {
    try {
      const historicalNotificationsResponse = await axios.get(
        `${process.env.REACT_APP_API}/notifications/${currentUserId}?type=new_message`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
 
      const historicalNotifications = historicalNotificationsResponse.data;

      // Fetch real-time notifications from your state (notificationsSocket)
      const realTimeNotifications = notificationsSocket;
      const mergedNotifications = [
        ...historicalNotifications,
        ...realTimeNotifications.filter(realTimeNotif => {
          // Check if a notification with the same message ID exists in historical notifications
          return !historicalNotifications.some(
            historicalNotif =>
              historicalNotif.sender === realTimeNotif.sender && historicalNotif.recipient === realTimeNotif.recipient
          );
        }),
      ];

      // Combine historical and real-time notifications
      mergedNotifications.sort((a, b) => b.timestamp - a.timestamp);


      // Set the combined notifications in state
  
      const notifications = mergedNotifications;
      console.log("notifications", notifications);
      // Create an array to store the senderIds from notifications
      const senderIds = notifications.map(notification => notification.sender);
      console.log("senderIds", senderIds);
  
      // Fetch conversations for the senderIds using the provided API endpoint
      const conversationsWithLastMessage = [];
  
      for (const senderId of senderIds) {
        const conversationRes = await axios.get(
          `${process.env.REACT_APP_API}/conversations/find/${currentUserId}/${senderId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
  
        const conversation = conversationRes.data;
        console.log("conversation", conversation);
  
        // Fetch all messages for the conversation using the provided API endpoint
        const messagesRes = await axios.get(
          `${process.env.REACT_APP_API}/messages/${conversation._id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
  
        const messages = messagesRes.data;
  
        // Find the last message from the retrieved messages
        const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
  
        conversationsWithLastMessage.push({
          conversation,
          lastMessage,
        });
  
        // Fetch sender's information by senderId
        const senderInfoResponse = await axios.get(
          `${process.env.REACT_APP_API}/users/${senderId}`
        );
  
        // Store sender's information in the sendersInfo state
        setSendersInfo(prevSendersInfo => ({
          ...prevSendersInfo,
          [senderId]: senderInfoResponse.data, // Store sender info by senderId
        }));
      }
  
      // Now you have an array of objects containing conversation and lastMessage
      console.log("conversationsWithLastMessage", conversationsWithLastMessage);
      console.log("sendersInfo", sendersInfo);
      setConversationsWithLastMessage(conversationsWithLastMessage);
  
      setNotifications(notifications);
    } catch (err) {
      // Handle errors
      console.error(err);
    }
  };
  
  useEffect(() => {
    fetchNotifications();
  }, [notificationsSocket]);
  
  function truncateMessage(message, maxLength) {
    if (message.length > maxLength) {
      return message.substring(0, maxLength) + '...'; // Truncate and add ellipsis
    }
    return message; // Otherwise, return the original message
  }


  const handleNotificationClick = async notificationId => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API}/notifications/${notificationId}`,
        { read: true },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      history.push('/chat/chat');
      history.go(0);

      // You could also remove the notification from the list of unread notifications
    } catch (err) {}
  };

  if (notifications.length === 0) {
    return (
      <>
        <Box w="100%" display="flex" justifyContent="center" mb={2}>
          <Flex direction="row">
            {' '}
            <Icon
              as={MdOutlineNotificationsPaused}
              color="grey"
              w={14}
              h={14}
            />
          </Flex>
        </Box>
        <Box w="100%" display="flex" justifyContent="center">
          <Flex direction="row" align="center">
            <Text
              mb="5px"
              fontWeight="regular"
              color="grey"
              fontSize={{ base: 'md', md: 'md' }}
            >
              Désolé, pas de messages à l'horizon
            </Text>
          </Flex>
        </Box>
      </>
    );
  } else {
    return (
      <>
        {
          notifications.map(notification => (
  <MenuItem
    w="100%"
    bg={notification?.read ? 'none' : unread}
    px="0"
    borderRadius="20px"
    mb="4px"
    mt="2px"
    p={2}
    _hover={bgItem}

    boxShadow= "0px 40px 58px -20px rgba(112, 144, 176, 0.12)"
  >
    <Flex
      w="100%"
      onClick={() => handleNotificationClick(notification._id)}
      key={notification._id}
      align="center"
    >
      <Avatar
        size="lg" // You can adjust the size as needed
        src={sendersInfo[notification.sender]?.profilePicture} // Use the sender's profile picture
        me="14px"
        bgGradient="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
      />
      <Flex flexDirection="column">
        <Text
          mb="5px"
          fontWeight="bold"
          color={read}
          fontSize={{ base: 'md', md: 'md' }}
        >
          {sendersInfo[notification.sender]?.firstName}{' '}
          {sendersInfo[notification.sender]?.lastName} 
        </Text>
        <Flex alignItems="center">
          <Text
            fontSize={{ base: 'sm', md: 'sm' }}
            lineHeight="100%"
            color={read}
          >
          {/* Truncate the message to display only the first sentence with an ellipsis */}
       {/* Truncate the message to a specific number of characters with an ellipsis */}
       {truncateMessage(
              conversationsWithLastMessage.find(
                convo =>
                  convo.conversation.members.includes(currentUserId) &&
                  convo.conversation.members.includes(notification.sender)
              )?.lastMessage?.text,
              50 // Specify the maximum number of characters before truncation
            )}
          </Text>
        </Flex>
      </Flex>
    </Flex>
    <Flex ml={6}>
      {/* Render a nice Button for replying to the message */}
      <Button
        colorScheme="teal" // Choose a color scheme
        size="sm" // Adjust the size as needed
        onClick={() => handleNotificationClick(notification._id)}
      >
        Reply
      </Button>
    </Flex>
  </MenuItem>
))
}
      </>
    );
  }
}
