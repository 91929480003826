import { Box, useStyleConfig } from "@chakra-ui/react";
import React, { useState, useEffect,useRef,useCallback } from 'react';

const Card = React.forwardRef((props, ref) => {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("Card", { variant });

  return (
    <Box ref={ref} __css={styles} {...rest}>
      {children}
    </Box>
  );
});

export default Card;
