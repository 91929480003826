// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Progress,
  Text,
  useColorModeValue,
  Button,
  Tooltip,
} from '@chakra-ui/react';
// Custom components
import { NavLink, useHistory } from 'react-router-dom';

import Card from 'components/card/Card.js';
import IconBox from 'components/icons/IconBox';
import Menu from 'components/menu/MainMenu';
import React, { useState } from 'react';
import {
  MdBatteryCharging30,
  MdBatteryCharging60,
  MdBatteryChargingFull,
  MdBatteryAlert,
} from 'react-icons/md';
// Assets
import { MdOutlineCloudDone } from 'react-icons/md';
const CustomProgressBar = ({ value }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseOver = () => {
    setShowTooltip(true);
  };
  const valueAsNumber = parseFloat(value); // or parseInt(value, 10) for integers
  const remainingTime = 100 - valueAsNumber;
  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  return (
    <Tooltip label={`Temps restant : ${remainingTime}%`} isOpen={showTooltip}>
      <Progress
        colorScheme={
          currentUser.memberShip === 'Débutant'
            ? 'green'
            : currentUser.memberShip === 'Professionnel'
            ? 'yellow'
            : currentUser.memberShip === 'Expert'
            ? 'red'
            : 'blue'
        }
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        value={value}
        textAlign="start"
        w="100%"
      />
    </Tooltip>
  );
};
export default function Banner(props) {
  const { used, total } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const textColorSecondary = 'gray.400';
  const box = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  const history = useHistory();
  const handleSubscribe = () => {
    // Functionality for subscribing goes here
    // You can add redirection, API calls, or any necessary logic
    history.push('/admin/memberships');
  };
  const startDate = new Date(currentUser.memberShipPeriod?.startDate);
  const startDateFormatted = startDate.toLocaleDateString();
  const endDate = new Date(currentUser.memberShipPeriod?.endDate);
  const endDateFormatted = endDate.toLocaleDateString();
  const currentDate = new Date(); // Get the current date
  const differenceInMs = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to days
  const differenceInDays = Math.floor(differenceInMs / (1000 * 3600 * 24));

  const differenceInMsConsumed = currentDate.getTime() - startDate.getTime();

  // Convert milliseconds to days
  const differenceInDaysConsumed = Math.floor(
    differenceInMsConsumed / (1000 * 3600 * 24)
  );

  console.log('differenceInDaysConsumed', differenceInDaysConsumed);
  console.log('differenceInDays', differenceInDays);

  return (
    <Card shadow="lg" mb={{ base: '0px', lg: '5px' }} align="center">
      {currentUser.memberShip !== '' && (
        <Flex w="100%">
          <Menu ms="auto" />
        </Flex>
      )}

      <Box w="100%" align="center" pt={currentUser.memberShip === 'Gratuit' || currentUser.memberShip === '' && '60px'}>
        <IconBox
          mx="auto"
          h="100px"
          w="100px"
          icon={
            <Icon
              as={
                currentUser.memberShip === 'Débutant'
                  ? MdBatteryCharging30
                  : currentUser.memberShip === 'Professionnel'
                  ? MdBatteryCharging60
                  : currentUser.memberShip === 'Expert'
                  ? MdBatteryChargingFull
                  : MdBatteryAlert
              }
              color={
                currentUser.memberShip === 'Débutant '
                  ? '#82d173'
                  : currentUser.memberShip === 'Professionnel'
                  ? '#F9B710'
                  : currentUser.memberShip === 'Expert'
                  ? '#ED4C40'
                  : 'blue.400'
              }
              h="60px"
              w="60px"
            />
          }
          bg={box}
        />
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="3xl"
          mt="10px"
        >
          Compte {currentUser.memberShip ? currentUser.memberShip : 'Gratuit'}
        </Text>
        <Text
          color={textColorSecondary}
          fontSize="md"
          maxW={{ base: '100%', xl: '80%', '3xl': '60%' }}
          mx="auto"
        >
          Superviser votre plan de soucription
        </Text>
        {(currentUser.memberShip === '' || currentUser.memberShip === 'Gratuit' ) && (
          <Box w="100%" mt="50px">
            <Button variant="brand" onClick={handleSubscribe}>
              Changer ma formule
            </Button>
          </Box>
        )}

        {(currentUser.memberShip !== 'Gratuit' || currentUser.memberShip !== '') && (
          <Box w="100%" mt="80px">
            <Flex w="100%" justify="space-between" mb="10px">
              <Text color={textColorSecondary} fontSize="sm" maxW="40%">
                {startDateFormatted}
              </Text>
              <Text color={textColorSecondary} fontSize="sm" maxW="40%">
                {endDateFormatted}
              </Text>
            </Flex>
            <CustomProgressBar
              colorScheme={
                currentUser.memberShip === 'Débutant '
                  ? 'green'
                  : currentUser.memberShip === 'Professionnel'
                  ? 'yellow'
                  : currentUser.memberShip === 'Expert'
                  ? 'red'
                  : 'blue'
              }
              value={(
                (differenceInDaysConsumed / differenceInDays) *
                100
              ).toFixed(2)}
              w="100%"
            />

            <Flex justify="space-between" mt="10px">
              <Text color={brandColor} fontSize="md">
                Prochain paiement : {endDateFormatted}
              </Text>
              {/* Add more date sections here as needed */}
            </Flex>
          </Box>
        )}
      </Box>
    </Card>
  );
}
